import React from 'react';
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import ResetPasswordForm from '../components/forms/ResetPasswordForm';
import { SiteConfig }  from '../SiteConfig';

function ResetPassword() {

    const { t } = useTranslation();
    const { token } = useParams();

    return <div>
            <Helmet>
                <title>{t('Reset password')}</title>
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <h1>{t('Reset password')}</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col">
                  <ResetPasswordForm token={token}/>
                </div>
              </div>
            </div>
          </div>
}

export default ResetPassword;
