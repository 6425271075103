import Spinner from 'react-bootstrap/Spinner';

function LoadingSpinner(props) {

  const spinnerStyle = {
    height: props.fixedHeight ? props.fixedHeight + 'px' : 'calc(100vh - ' + String(props.heightOffset ? props.heightOffset : 0) + 'px)'
  };

  return (

  <div className="container">
    <div className="d-flex justify-content-center align-items-center" style={spinnerStyle}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  </div>

  )
};


export default LoadingSpinner;
