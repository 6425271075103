import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import Helpers from '../components/Helpers';
import { SiteConfig } from '../SiteConfig';

function Terms() {
    const { t } = useTranslation();

    const {createMarkup} = Helpers();

    return <div>
            <Helmet>
                <title>{t('Terms of Use')}</title>
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Terms of Use')}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col bold-h3">
                <span dangerouslySetInnerHTML={createMarkup(t('Terms of Use Page'))}/>
              </div>
            </div>
          </div>
}

export default Terms;
