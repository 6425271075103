import React, { useEffect , useState} from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next';
import Helpers from '../Helpers'

function Terms(props) {

  useEffect(() => {
      setShow(props.show);
  }, [props.show]);

  const [show, setShow] = useState(false);

  const { t } = useTranslation();
  const {createMarkup} = Helpers();


  return (

      <Modal
        animation={false}
        show={show }
        onHide={props.handleClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>{t('Terms & conditions of use')}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span dangerouslySetInnerHTML={createMarkup(t('Terms of Use Page'))}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>

  );
}

export default Terms
