import React, { useEffect , useState, useRef} from "react";
import { CSVLink } from "react-csv";
import Button from 'react-bootstrap/Button'
import { useTranslation } from 'react-i18next';
import { guidelinesService } from '../services/guidelinesService';
import ButtonSpinner from './spinners/ButtonSpinner';

import { SiteConfig }  from '../SiteConfig';

function MyListCSVDownload({list_id,disabled}) {

  const { t, i18n } = useTranslation();

  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState(null);
  const [fetching, setFetching] = useState(false);

  const csvInstance = useRef();

  useEffect(() => {
    if (data && csvInstance.current && csvInstance.current.link) {
    setTimeout(() => {
      csvInstance.current.link.click();
      setFetching(false);
    });
  }

  }, [data]);


  return (
    <>{disabled}
      <Button
        variant="outline-primary"
        disabled={disabled || fetching}
        onClick={async () => {
          if (disabled) {
            return;
          }
          setFetching(true);
          guidelinesService.getListExport(list_id,SiteConfig.docType, i18n.language)
          .then(
              (result) => {

                result.headers.forEach((item, i) => {
                  result.headers[i] = t(item);
                });

                setHeaders(result.headers);
                setData(result.data);
              },
              error => {

              }
          );

        }}
      >
        <i className="fa fa-file"></i>&nbsp;{t('Export as CSV')}{fetching && <ButtonSpinner/>}
      </Button>

      {data &&
        <CSVLink
          data={data}
          headers={headers}
          filename={"my-guidelines-list.csv"}
          ref={csvInstance}
          data-interception='off'
        />
      }
    </>
  )
};

export default MyListCSVDownload;
