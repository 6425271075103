import { apiService } from './apiService';

export const listsService = {

    getListById,
    getLists,
    newList,
    renameList,
    deleteList,
    getListsForGuidelineId,
    saveGuidelineToLists,
    saveAllGuidelinesToLists,
    getShareForGuidelineId,
    getSharedForListId
};

function getListById(id, language_code) {

  let data = new FormData();
  data.append('language',language_code);

  return apiService.apiGet(process.env.REACT_APP_API_URL + '/iatg_list/list/'+ id,data);
}

function getLists(doc_type, language_code) {

  let data = new FormData();
  data.append('doc_type',doc_type);
  data.append('language',language_code);

  return apiService.apiGet(process.env.REACT_APP_API_URL + '/iatg_list/all_lists',data);
}

function newList(list_name, doc_type) {

  let data = new FormData();
  data.append('list_name',list_name);
  data.append('doc_type',doc_type);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/iatg_list/new',data);
}

function renameList(list_id, list_name) {

  let data = new FormData();
  data.append('list_name',list_name);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/iatg_list/rename/' + list_id, data);
}

function deleteList(list_id, doc_type) {

  let data = new FormData();
  data.append('doc_type',doc_type);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/iatg_list/delete/' + list_id, data);
}

function getListsForGuidelineId(id) {
  return apiService.apiGet(process.env.REACT_APP_API_URL + '/iatg_list/listsforguideline/'+ id);
}

function saveGuidelineToLists(guideline_ids, list_ids) {

  let data = new FormData();
  data.append('guideline_ids', guideline_ids);
  data.append('list_ids', list_ids);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/iatg_list/save_guideline', data);
}

function saveAllGuidelinesToLists(guideline_ids, list_ids) {

  let data = new FormData();
  data.append('guideline_ids', guideline_ids);
  data.append('list_ids', list_ids);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/iatg_list/save_all', data);
}
function getShareForGuidelineId(id) {
  return apiService.apiGet(process.env.REACT_APP_API_URL + '/iatg_list/share/'+ id);
}

function getSharedForListId(id,language_code) {

  let data = new FormData();
  data.append('language',language_code);

  return apiService.apiGet(process.env.REACT_APP_API_URL + '/iatg_list/shared/'+ id,data);
}
