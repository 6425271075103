import React, { useEffect , useState} from "react";
import { useHistory } from "react-router-dom";
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import LoadingSpinner from '../spinners/LoadingSpinner'
import ModalSpinner from '../spinners/ModalSpinner'
import ErrorModal from '../modals/ErrorModal';
import ListSaveToFormModal from '../modals/ListSaveToFormModal';
import ListFormModal from '../modals/ListFormModal';
import { useTranslation } from 'react-i18next';
import GuidelineDetailsModal from '../modals/GuidelineDetailsModal';
import { guidelinesService } from '../../services/guidelinesService';
import { authenticationService } from '../../services/authenticationService';
import { notificationService } from '../../services/notificationService';
import Helpers from '../Helpers';

export default function GuidelineLinksGrid(props) {

  const [linkedGuidelines, setLinkedGuidelines] = useState([]);
  const [guideline, setGuideline] = useState(null);
  const [recordsTotal, setRecordsTotal] = useState(null);
  const [selectedGuidelineId, setSelectedGuidelineId] = useState(null);
  const [selectedGuidelineIdTimestamp, setSelectedGuidelineIdTimestamp] = useState(null);
  const [selectedListGuidelineIds, setSelectedListGuidelineIds] = useState([]);
  const [showListFormModal, setShowListFormModal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showListSaveToFormModal, setShowListSaveToFormModal] = useState(false);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const {docTypeIdToDocType, createMarkup} = Helpers();

  const onCloseListFormModalCallback = (reloadData) => {
    if (reloadData)
      setSelectedGuidelineId(selectedGuidelineId);

    setShowListFormModal(false);
    setShowListSaveToFormModal(true);
  }

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const showGuidelinesModal = (guideline_id) => {
    setSelectedGuidelineId(guideline_id);
    setSelectedGuidelineIdTimestamp(new Date().valueOf());
  }

  const onCloseShowListSaveToFormModalCallback = (reloadData,showAddListModal) => {
    setShowListSaveToFormModal(false);
    setShowListFormModal(showAddListModal);
  }

  const showListSaveToForm = (e,guideline_id) => {
    e.preventDefault();
    e.stopPropagation();
    setShowListSaveToFormModal(true);
    setSelectedListGuidelineIds([guideline_id]);
  }

  useEffect(() => {

    const retrieveData  = async () => {

        setLoadingData(true);
          guidelinesService.getLinkedGuidelinesById(props.guideline_id)
          .then(
              (result) => {
                  var arrGuidelines =  [];

                  for(var i in result.items)
                      arrGuidelines.push(result.items[i]);

                  setInitiallyLoaded(true);
                  setLoadingData(false);
                  setLinkedGuidelines(arrGuidelines);
                  setGuideline(result.guideline);
                  setRecordsTotal(arrGuidelines.length);
                  //props.receiveGuideline(result.guideline);

                  //update local notifications
                  notificationService.markNotificationAsRead(props.guideline_id);
              },
              error => {

                if (error.message === '401' ) {
                  authenticationService.logout();
                } else if (error.message === '500') {
                  setShowErrorModal(true);
                } else if (error.message === '403') {
                  history.push('/');
                }

              }
          );

    }

    retrieveData();

  }, [props.guideline_id, history]);



  return (

      <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      <ListSaveToFormModal show={showListSaveToFormModal} guideline_ids={selectedListGuidelineIds} handleModalOpenClose={onCloseShowListSaveToFormModalCallback} />
      <ListFormModal show={showListFormModal}  handleModalClose={onCloseListFormModalCallback} />
      { initiallyLoaded && loadingData &&
        <ModalSpinner/>
      }
      { initiallyLoaded  ?
        <>
          <div className=" row">
            <div className="d-md-flex col">
              <div
                className="pointer mb-4 me-md-4"
                onClick={() => showGuidelinesModal(props.guideline_id)}
                >
                <div className="h4">
                  <h3>{docTypeIdToDocType(guideline?.module.doc_type)} {guideline?.module.number} - {guideline?.module.title}</h3>
                  <h4>{t('Edition')}&nbsp;{guideline?.module.edition},&nbsp;{t('Amendment')}&nbsp;{guideline?.module.edition}</h4>
                  <h4>{t('Section')}&nbsp;{guideline?.section}</h4>
                </div>
                <span dangerouslySetInnerHTML={createMarkup(guideline?.text)}/>

              </div>
              <Button
                className="mb-4 align-self-center"
                variant="primary"
                size="sm"
                onClick={(e) => showListSaveToForm(e,guideline?.id)}
              >
                {t('Save')}
              </Button>
          </div>
        </div>
        <div className="pb-4">
            { recordsTotal > 0 ?
            <>
              <Table striped bordered hover className="custom-responsive">
                <thead>
                  <tr>
                    <th></th>
                    <th>{t('Section')}</th>
                    <th>{t('Text')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {linkedGuidelines.sort((a, b) => a.module.number - b.module.number).map(function(value, index){
                  return( <tr
                      className="pointer"
                      key={ index }
                      onClick={() => showGuidelinesModal(value.id)}>
                      <td className="text-nowrap">{docTypeIdToDocType(value.module.doc_type)} {value.module.number}</td>
                      <td>{value.section}</td>
                      <td>
                        <span dangerouslySetInnerHTML={createMarkup(value.text)}/>
                      </td>
                      <td>
                        <Button
                          className="mb-4 align-self-center"
                          variant="primary"
                          size="sm"
                          onClick={(e) => showListSaveToForm(e,value.id)}
                        >
                          {t('Save')}
                        </Button>
                      </td>
                    </tr>)
                  })
                }
                </tbody>
              </Table>
              <GuidelineDetailsModal guideline_id={selectedGuidelineId} guideline_id_timestamp={selectedGuidelineIdTimestamp}/>

            </>
            :
              <>{t('No linked items')}.</>
            }
          </div>
        </>
      :
     <LoadingSpinner heightOffset={200}/>
    }

  </>

  );
}
