
import React  from "react";
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

function Home() {
    const { t } = useTranslation();
    //const [currentUser, setCurrentUser] = useState(null);

    /*
    useEffect(() => {
      redirect to home if already logged in
      setCurrentUser( authenticationService.currentUserValue);

    },[]);
    */

    return <div>
            <Helmet>
                <title>{t('Home')}</title>
                <link rel="canonical" href="/" />
            </Helmet>

            <header className="masthead  d-flex align-items-center flex-column  justify-content-center">
              <div className="h-100 w-100 intro-text d-flex align-items-center flex-column  justify-content-center">
                <div className="intro-heading">{t('IATG online digital tool')}</div>
                <div className="container">
                  <h6 className="mb-4 intro-description">{t('IATG HomePage Description')}</h6>
                </div>
                <div className="intro-buttons">
                  <Link className="btn btn-arrow d-flex  btn-xl text-uppercase m-2 mt-4" to="/search"><span>{t('Search')}</span><div className="link-arrow"><i className="fa fa-arrow-right"></i></div></Link>
                  <Link className="btn btn-arrow  d-flex  btn-xl text-uppercase m-2 mt-4" to="/lists"><span>{t('My Lists')}</span><div className="link-arrow"><i className="fa fa-arrow-right"></i></div></Link>
                </div>
              </div>
            </header>

          </div>
}

export default Home;
