
import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import ForgottenPasswordForm from '../components/forms/ForgottenPasswordForm';
import { SiteConfig }  from '../SiteConfig';

function ForgottenPassword() {
    const { t } = useTranslation();
    return <div>
            <Helmet>
                <title>{t('Forgotten password')}</title>
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>
            <div className="container">
              <div className="controls">
                <div className="row">
                  <div className="col">
                    <h1>{t('Forgotten password')}</h1>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <ForgottenPasswordForm/>
                </div>
              </div>
            </div>
          </div>
}

export default ForgottenPassword;
