
import React, { useState, useCallback} from "react";

import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import ButtonSpinner from '../spinners/ButtonSpinner';
import LoadingSpinner from '../spinners/LoadingSpinner'
import RecaptchaComponent from '../RecaptchaComponent';

import { useTranslation } from 'react-i18next';

import { Formik, Field, Form, ErrorMessage } from 'formik';

import { SiteConfig }  from '../../SiteConfig';

import * as Yup from "yup";


import { apiService } from '../../services/apiService';

function ContactForm(props) {

  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const [feedbackText, setFeedbackText] = useState("");
  const initiallyLoaded = true;

  const onToken =  useCallback(async(token) => {
    setReCaptchaToken(token);
  },[setReCaptchaToken]);

  return (
    <Formik
            initialValues={{
                name:'',
                email: '',
                phone: '',
                message: '',
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string()
                  .required(t('Please enter your name')),
                email: Yup.string()
                  .email(t('Email is invalid'))
                  .required(t('Please enter your email address')),
                phone: Yup.string()
                  .required(t('Please enter your phone number')),
                message: Yup.string()
                  .required(t('Please enter your message')),
            })}
            onSubmit={fields => {

              setFeedbackText('');

              setSubmitting(true);
              // Fetch the accessToken from the server
              let data = new FormData();
              data.append('name',fields.name);
              data.append('email',fields.email);
              data.append('phone',fields.phone);
              data.append('message',fields.message);
              data.append('token',reCaptchaToken);
              data.append('site_tag',SiteConfig.siteTag);

              return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/cm_contact',data)
                .then(
                    response => {
                      setSubmitted(true);
                    },
                    error => {
                      setSubmitting(false);
                      setFeedbackText(t('Contact form error'));
                    }
                );
            }}
            >
            {({ errors, status, touched }) => (

              <>
              { initiallyLoaded  ?
                <>
                  {feedbackText &&  <Alert variant="danger">
                                    {feedbackText}
                                  </Alert>}
                      <Form>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="email">{t('Your name')}</label>
                                <InputGroup>
                                  <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-user" aria-hidden="true"></i></InputGroup.Text>
                                  <Field
                                    name="name"
                                    type="text"
                                    disabled={submitted}
                                    className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                                    placeholder={t('Enter name')}
                                   />
                                  <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </InputGroup>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email">{t('Email address')}</label>
                                <InputGroup>
                                  <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-envelope" aria-hidden="true"></i></InputGroup.Text>
                                  <Field
                                    name="email"
                                    type="text"
                                    disabled={submitted}
                                    className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                                    placeholder={t('Enter email')}
                                   />
                                  <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                </InputGroup>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone">{t('Phone number')}</label>
                                <InputGroup>
                                  <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-phone" aria-hidden="true"></i></InputGroup.Text>
                                  <Field
                                    name="phone"
                                    type="text"
                                    disabled={submitted}
                                    className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}
                                    placeholder={t('Enter phone number')}
                                   />
                                  <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                                </InputGroup>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group h-100">
                                <label htmlFor="message">{t('Message')}</label>
                                <Field
                                  name="message"
                                  type="text"
                                  as="textarea"
                                  rows="8"
                                  disabled={submitted}
                                  className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')}
                                  placeholder={t('Enter message')}
                                 />
                                <ErrorMessage name="message" component="div" className="invalid-feedback" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group text-center col-md-12">
                              <button type="submit" className="btn btn-primary me-2" disabled={submitting || !reCaptchaToken || submitted}>{t('Send message')}  {(submitting && !submitted) && <ButtonSpinner/>}</button>
                          </div>
                        </div>

                        {submitted &&
                          <div className="row">
                            <div className="form-group text-center col-md-12">
                              <Alert variant="success">
                                {t('Contact form success')}
                              </Alert>
                            </div>
                          </div>
                        }
                        <div className="row">
                          <div className="form-group text-center col-md-12">
                            <small>{t('This site is protected by reCAPTCHA and the Google')}&nbsp;
                                <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">{t('Privacy Policy')}</a> {t('and')}&nbsp;
                                <a target="_blank" rel="noreferrer" href="https://policies.google.com/terms">{t('Terms of Service')}</a> {t('apply')}.
                            </small>
                          </div>
                        </div>
                    </Form>

                </>
                :
               <LoadingSpinner/>
              }
              <RecaptchaComponent action="contactform" onToken={onToken} sitekey={SiteConfig.recapchaV3SiteKey}/>

            </>

          )}
     </Formik>

  );
};

export default ContactForm;
