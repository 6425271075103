import { BehaviorSubject } from 'rxjs';
import { apiService } from './apiService';

const currentNotificationsSubject = new BehaviorSubject({notifications: [], getNotifications : false});

export const notificationService = {
    getNotifications,
    setNotifications,
    markNotificationAsRead,
    markNotificationAsReadAPI,
    currentNotifications: currentNotificationsSubject.asObservable(),
    get currentNotificationsValue () { return currentNotificationsSubject.value }
};

function getNotifications() {

  apiService.apiGet(process.env.REACT_APP_API_URL + '/notify/get')
    .then(
        (data) => {
          currentNotificationsSubject.next(
            {notifications: data.items, getNotifications : data.getNotifications}
          );
        },
        error => {
          if (error.message === '401') {
              currentNotificationsSubject.next({notifications: [], getNotifications : false});
          }
          if(error.message === '500') {
            //tbd
          }
        }
    );

}

function markNotificationAsReadAPI(notification_id) {
  return apiService.apiGet(process.env.REACT_APP_API_URL + '/notify/read/' +  notification_id);
}

function setNotifications(notifications) {
  currentNotificationsSubject.next(notifications);
}

function markNotificationAsRead(notification_id) {

  var currentNotificationsData = currentNotificationsSubject.value;

  for (const notification of currentNotificationsData.notifications.filter(x=> !x.read_at)) {
    if (notification.data.guideline.id === parseInt(notification_id,10)) {
      markNotificationAsReadAPI(notification.id).then(
        (result) => {
          notification.read_at = new Date();
          //debugger;
      });
    }
  }

  setNotifications({notifications: currentNotificationsData.notifications, getNotifications : currentNotificationsData.getNotifications});

}
