import React, { useState , useCallback} from "react";
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge'
import { SiteConfig }  from '../SiteConfig';

import GuidelinesSelectedListGrid from '../components/grids/GuidelinesSelectedListGrid';

function MyLists() {

    const { t } = useTranslation();
    const { id } = useParams();

    const [listName, setListName] = useState(null);

    const [listCount, setListCount] = useState(null);


    const receiveListInfo = useCallback((name,count) =>
    {
      setListName(name);
      setListCount(count);
    },[setListName,setListCount]);


    return <div>
            <Helmet>
                <title>{t('My lists')}</title>
                <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>

            <div className="controls mb-1">
              <div className="container">
                <div className="col">
                  { listName && <h1 className="text-capitalize">{ t(listName) } <Badge variant="info">{listCount} {t('results')}</Badge></h1> }
                </div>
              </div>
            </div>
              <GuidelinesSelectedListGrid receiveListInfo={receiveListInfo} list_id={id}/>
            </div>

}

export default MyLists;
