import React, { useEffect , useState, useCallback} from "react";
import { useHistory } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import LoadingSpinner from '../spinners/LoadingSpinner';
import ModalSpinner from '../spinners/ModalSpinner';
import ErrorModal from '../modals/ErrorModal';
import { useTranslation } from 'react-i18next';
import GuidelineDetailsModal from '../modals/GuidelineDetailsModal';
import { listsService } from '../../services/listsService';
import { authenticationService } from '../../services/authenticationService';
import Helpers from '../Helpers';

export default function GuidelinesSharedGrid(props) {

  const [guidelines, setGuidelines] = useState([]);
  const [recordsTotal, setRecordsTotal] = useState(null);
  const [selectedGuidelineId, setSelectedGuidelineId] = useState(null);
  const [selectedGuidelineIdTimestamp, setSelectedGuidelineIdTimestamp] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const {docTypeIdToDocType,createMarkup} = Helpers();

  const showGuidelinesModal = (guideline_id) => {
    setSelectedGuidelineId(guideline_id);
    setSelectedGuidelineIdTimestamp(new Date().valueOf());
  }

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const { receiveListInfo, list_id } = props;

  const retrieveData =  useCallback(async() => {
    setLoadingData(true);
      listsService.getSharedForListId(list_id)
      .then(
          (result) => {
            setInitiallyLoaded(true);
            setLoadingData(false);
            setGuidelines(result.guidelines);
            setRecordsTotal(result.guidelines.length);
            receiveListInfo({listName: result.listName,listOwner: result.listOwner});
          },
          error => {
            if (error.message === '401' ) {
              authenticationService.logout();
            } else if (error.message === '500') {
              setShowErrorModal(true);
            } else if (error.message === '403') {
              history.push('/');
            }
          }
      );
  },[list_id,history,receiveListInfo]);

  useEffect(() => {
    retrieveData();
  }, [retrieveData]);

  return (

      <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      { initiallyLoaded && loadingData &&
        <ModalSpinner/>
      }
      { initiallyLoaded  ?
        <>
        <div className="pb-4">
            { recordsTotal > 0 ?
            <>
              <Table striped bordered hover className="custom-responsive">
                <thead>
                  <tr>
                    <th>{t('#')}</th>
                    <th>{t('Section')}</th>
                    <th>{t('Text')}</th>
                  </tr>
                </thead>
                <tbody>
                {guidelines.sort((a, b) => a.module.number - b.module.number).map(function(value, index){
                  return( <tr
                      className="pointer"
                      key={ index }
                      onClick={() => showGuidelinesModal(value.id)}>
                      <td className="text-nowrap">{docTypeIdToDocType(value.module.doc_type)} {value.module.number}</td>
                      <td>{value.section}</td>
                      <td>
                        <span dangerouslySetInnerHTML={createMarkup(value.text)}/>
                      </td>

                    </tr>)
                  })
                }
                </tbody>
              </Table>
              <GuidelineDetailsModal guideline_id={selectedGuidelineId} guideline_id_timestamp={selectedGuidelineIdTimestamp}/>

            </>
            :
              <>{t('No guidelines')}.</>
            }
          </div>
        </>
      :
     <LoadingSpinner heightOffset={200}/>
    }

  </>

  );
}
