import NavDropdown from 'react-bootstrap/NavDropdown';
import { withTranslation, useTranslation } from 'react-i18next';
import { useEffect ,useCallback} from "react";

function LanguageSwitcher({ i18n, setExpanded }) {

  document.body.dir = i18n.dir();
  document.body.lang = i18n.language;
  document.documentElement.dir = i18n.dir();
  document.documentElement.lang = i18n.language;

  const { t } = useTranslation();

  const changeLanguage = (e,lng) => {

     e.stopPropagation();
    e.preventDefault();
    i18n.changeLanguage(lng);
    setExpanded(false);
  }


  const retrieveData =  useCallback(async() => {
    i18n.on('languageChanged', function(lng) {
      //console.log(new Date().valueOf() + ' ' + lng);
    })
  },[i18n]);

  useEffect(() => {
    retrieveData();
  }, [retrieveData]);


//  const availableLanguages = ['ar','de','en','es','fr','ru'];
  const availableLanguages = [{'code':'ar','label':'Arabic'},{'code':'en','label':'English'},{'code':'es','label':'Spanish'},{'code':'fr','label':'French'},{'code':'pt','label':'Portuguese'},{'code':'ru','label':'Russian'}];
  return (
    <NavDropdown
      title={t('Language') + ': (' + t(availableLanguages.find(x => x.code === i18n.language.substring(0,2)).label) + ')'}
      className="me-auto text-uppercase no-padding-start-md"
    >
      {availableLanguages.sort().map(element =>
           <NavDropdown.Item
            className="text-dark"
             active={i18n.language.substring(0,2) === element.code }
             key={element.code}
             onClick={(e) => changeLanguage(e,element.code)}
           >
           {t(element.label)}
           </NavDropdown.Item>
       )}
     </NavDropdown>
  )
}

export default withTranslation()(LanguageSwitcher);
