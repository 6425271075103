import { BehaviorSubject } from 'rxjs';
import { apiService } from './apiService';

import { SiteConfig }  from '../SiteConfig';

const currentMyListCountSubject = new BehaviorSubject(null);

export const myListCounterService = {
    getMyListCount,
    setMyListCount,
    currentMyListCount: currentMyListCountSubject.asObservable(),
    get currentMyListCountValue () { return currentMyListCountSubject.value }
};

function getMyListCount() {

  let data = new FormData();
  data.append('doc_type',SiteConfig.docType);

    apiService.apiPostForm(process.env.REACT_APP_API_URL + '/iatg_list/count',data )
      .then(
          (count) => {
            currentMyListCountSubject.next(count);
          },
          error => {
            if (error.message === '401'
            || error.message === '500') {
              currentMyListCountSubject.next(0);
            }
          }
      );

}

function setMyListCount(count) {
  currentMyListCountSubject.next(count);
}
