import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';
import LoadingSpinner from '../spinners/LoadingSpinner'
import ModalSpinner from '../spinners/ModalSpinner'
import ErrorModal from '../modals/ErrorModal';
import ListFormModal from '../modals/ListFormModal';
import ListDeleteModal from '../modals/ListDeleteModal';
import { useTranslation } from 'react-i18next';
import { listsService } from '../../services/listsService';
import { authenticationService } from '../../services/authenticationService';
import { SiteConfig } from '../../SiteConfig';

export default function GuidelineLinksGrid(props) {

  const [lists, setLists] = useState([]);
  const [listRecordsTotal, setListRecordsTotal] = useState(null);
  const [selectedListId, setSelectedListId] = useState(null);
  const [loadingListData, setLoadingListData] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showListFormModal, setShowListFormModal] = useState(false);
  const [showListDeleteFormModal, setShowListDeleteFormModal] = useState(false);
  const [listsInitiallyLoaded, setListsInitiallyLoaded] = useState(false);
  const [canCreateLists, setCanCreateLists] = useState(false);

  const { t, i18n } = useTranslation();
  const history = useHistory();

  const addToClipboard = (e, sharePath) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(window.location.protocol + "//" + window.location.host + sharePath);
  }

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const onCloseListFormModalCallback = (reloadData) => {
    setShowListFormModal(false);
    if (reloadData)
      retrieveListData();
  }

  const onCloseListDeleteFormModalCallback = (reloadData) => {
    setShowListDeleteFormModal(false);
    if (reloadData)
      retrieveListData();
  }

  const showListForm = (e, list_id) => {

    e.preventDefault();
    e.stopPropagation();
    setShowListFormModal(true);
    setSelectedListId(list_id);
  }

  const showListDeleteForm = (e, list_id) => {
    e.preventDefault();
    e.stopPropagation();
    setShowListDeleteFormModal(true);
    setSelectedListId(list_id);
  }

  const selectList = (list_id) => {
    history.push('/lists/' + list_id);
  }

  const retrieveListData = useCallback(async () => {

    setLoadingListData(true);
    listsService.getLists(SiteConfig.docType, i18n.language)
      .then(
        (result) => {

          result.lists.forEach((item, i) => {
            result.lists[i].list_name = t(item.list_name);
          });

          setListsInitiallyLoaded(true);
          setLoadingListData(false);
          setLists(result.lists);
          setListRecordsTotal(result.lists.length);
          setCanCreateLists(result.canCreateLists);

        },
        error => {

          if (error.message === '401') {
            authenticationService.logout();
          } else if (error.message === '500') {
            setShowErrorModal(true);
          } else if (error.message === '403') {
            history.push('/');
          }

        }
      );

  }, [history, i18n.language, t]);

  useEffect(() => {
    retrieveListData();

  }, [retrieveListData]);



  return (

    <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      <ListFormModal show={showListFormModal} list_id={selectedListId} handleModalClose={onCloseListFormModalCallback} />
      <ListDeleteModal show={showListDeleteFormModal} list_id={selectedListId} handleModalClose={onCloseListDeleteFormModalCallback} />
      {listsInitiallyLoaded && loadingListData &&
        <ModalSpinner />
      }
      {listsInitiallyLoaded ?
        <>

          <div className="pb-4">
            {listRecordsTotal > 0 ?
              <>


                <div className="row">
                  <div className="col d-flex justify-content-end">
                    {canCreateLists &&
                      <Button
                        className="float-end mb-3 btn-arrow d-flex"
                        onClick={(e) => showListForm(e)}
                        variant="dannoneger"
                      ><span>{t('New list')}</span>

                        <div className="link-arrow"><i className="fa fa-arrow-right"></i></div>
                      </Button>
                    }
                  </div>
                </div>

                <div className="flex-table header " role="rowgroup">
                  <div className="flex-row pointer module" role="columnheader">
                    <strong>{t('#')}</strong>
                  </div>
                  <div className="flex-row pointer title" role="columnheader">
                    <strong>{t('List name')}</strong>
                  </div>

                  <div className="flex-row pointer list-actions" role="columnheader">
                  </div>

                </div>

                {lists.sort((a, b) => a.id - b.id).map(function (value, index) {
                  return (<div
                    className="flex-table hover-effect table-row pointer"
                    role="rowgroup"
                    key={index}
                    onClick={() => selectList(value.id)}
                  >
                    <div
                      className="flex-row module"
                      role="cell"
                    >{index + 1}</div>
                    <div
                      className="flex-row title pt-0 pb-0"
                      role="cell"
                    >
                      <span
                        className="position-relative pt-3 pb-3 pe-4  nav-link text-body text-capitalize"
                        data-count={value.get_mylist_count} >
                        {value.list_name}
                      </span>
                    </div>

                    <div
                      className="flex-row list-actions pe-0"
                      role="cell"
                    >
                      <ButtonGroup
                        className="float-end-desktop"
                        aria-label="{t('List actions')}"
                      >
                        <OverlayTrigger
                          key={value.id}
                          placement="top"
                          rootClose={false}
                          rootCloseEvent="click"
                          trigger="click"
                          overlay={
                            <Popover >
                              <Popover.Content>
                                <Form>
                                  <div className="d-flex mb-2 justify-content-between">
                                    <Form.Text className="mb-1">
                                      {t('Added to Clipboard!')}
                                    </Form.Text>
                                    <button type="button" class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                                  </div>
                                  <Form.Control
                                    className="me-2"
                                    type="text"
                                    value={window.location.protocol + "//" + window.location.host + value.share_link} />
                                  <Form.Text>
                                    {t('Highlight text above and copy if clipboard is empty')}
                                  </Form.Text>
                                </Form>
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <Button
                            className="float-end pointer me-2 hasSpinner btn-arrow  d-flex btn btn-none btn-sm"
                            onClick={(e) => addToClipboard(e, value.share_link)}
                            variant="dannoneger"
                          ><span>{t('Share')}</span><div className="link-arrow"><i className="fa fa-share"></i></div></Button>
                        </OverlayTrigger>
                        <Button
                          className="float-end pointer me-2 hasSpinner btn-arrow d-flex btn btn-none btn-sm btn-rename"
                          onClick={(e) => showListForm(e, value.id)}
                          variant="dannoneger"
                        ><span>{t('Rename')}</span><div className="link-arrow link-rename"><i className="fa fa-i-cursor"></i></div></Button>
                        <Button
                          className="float-end pointer me-2 hasSpinner btn-arrow d-flex btn btn-none btn-sm btn-delete"
                          onClick={(e) => showListDeleteForm(e, value.id)}
                          variant="dannoneger"
                        ><span>{t('Delete')}</span><div className="link-arrow link-delete"><i className="fa fa-trash"></i></div></Button>

                      </ButtonGroup>
                    </div>


                  </div>)
                })
                }

              </>
              :
              <>{t('No lists')}.</>
            }
          </div>
        </>
        :
        <LoadingSpinner heightOffset={200} />
      }

    </>

  );
}
