import React , {useState, useCallback} from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import GuidelinesSharedGrid from '../components/grids/GuidelinesSharedGrid';

import { SiteConfig }  from '../SiteConfig';

function Shared() {

  const { t } = useTranslation();
  const { id } = useParams();

  const [listName, setListName] = useState(null);
  const [listOwner, setListOwner] = useState(null);

  const receiveListInfo = useCallback((data) =>
  {
    setListName(data.listName);
    setListOwner(data.listOwner);
  },[setListName,setListOwner]);

  return (

    <div>
      <Helmet>
          <title>{t('Shared')}</title>
          <bodyAttributes class={'fill-bg ' + SiteConfig.siteTag}></bodyAttributes>
      </Helmet>
      <div className="controls">
        <div className="container">
          <div className="col">
            <h1>{ (listName || listOwner) && <>{listName} <small>{t('shared by')} {listOwner}</small></>}&nbsp;</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col">
          <GuidelinesSharedGrid receiveListInfo={receiveListInfo} list_id={id}/>
        </div>
      </div>

    </div>
  );
}

export default Shared;
