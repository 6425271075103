import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import { useHistory  } from 'react-router-dom';
import GuidelinesGrid from '../components/grids/GuidelinesGrid';

function Search() {

  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div>
      <Helmet>
          <title>{t('Search')}</title>
      </Helmet>
      <div className="row px-2">
        <div className="col-md-12">
          <GuidelinesGrid history={history}/>
        </div>
      </div>

    </div>
  );
}

export default Search;
