import React from "react";
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import ListsGrid from '../components/grids/ListsGrid';

function Lists() {

    const { t } = useTranslation();

    return <div>
            <Helmet>
                <title>{t('My Lists')}</title>
            </Helmet>
            <div className="controls mb-1">
              <div className="container">
                <div className="col">
                  <h1>{t('My Lists')}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col">
                <ListsGrid/>
              </div>
            </div>
          </div>
}

export default Lists;
