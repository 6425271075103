import React, { useEffect, useCallback  } from 'react';


const RecaptchaComponent = ({ action, sitekey, onToken }) => {

  const handleLoaded = useCallback(async() => {
    window.grecaptcha.ready(_ => {

      const getToken = () => {

        window.grecaptcha
          .execute(sitekey, { action: action })
          .then(token => {

            onToken(token)
          })
      };

      getToken();

      //need to refresh the token periodically
      // as it expires every two minutes, should really
      // be done on form submit
      
      setInterval(() => {
        getToken();
      },90000);

    })
  },[action, sitekey,onToken])

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render="+ sitekey
    script.addEventListener("load", handleLoaded)
    document.body.appendChild(script)
  },[handleLoaded,sitekey])

  return (
    <>
      <div
        className="g-recaptcha"
        data-sitekey={sitekey}
        data-size="invisible"
      ></div>
    </>
  )
}

export default RecaptchaComponent;
