import { apiService } from './apiService';

export const guidelinesService = {
    getTerms,
    getTermsWithCount,
    getSearchFilters,
    guidelines,
    getGuidelineById,
    getLinkedGuidelinesById,
    getList,
    getListExport,
    postList,
    postSelectedList,
    postDetailedList,
    putToList,
    deleteFromList,
};

function getTerms(doc_type, sitetag, language_code) {

  let data = new FormData();
  data.append('site_tag',sitetag);
  data.append('doc_type',doc_type);
  data.append('language',language_code);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/terms',data);
}

function getTermsWithCount(doc_type, sitetag, language_code) {

  let data = new FormData();
  data.append('site_tag',sitetag);
  data.append('doc_type',doc_type);
  data.append('language',language_code);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/terms_count',data);

}
function getSearchFilters(doc_type, sitetag, language_code) {

  let data = new FormData();
  data.append('site_tag',sitetag);
  data.append('doc_type',doc_type);
  data.append('language',language_code);

  return  Promise.all([
      apiService.apiPostForm(process.env.REACT_APP_API_URL + '/terms',data),
      apiService.apiPostForm(process.env.REACT_APP_API_URL + '/rrpls',data),
      apiService.apiPostForm(process.env.REACT_APP_API_URL + '/modules',data)
 ])
   .then(([terms, rrpls, modules]) => {
     return {
       terms,
       rrpls,
       modules
     };
   })
}

function guidelines(data) {
  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/guidelines',data);
}

function getGuidelineById(id,language_code) {

  let data = new FormData();
  data.append('language',language_code);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/guideline/'+ id,data);
}

function getLinkedGuidelinesById(id) {
  return apiService.apiGet(process.env.REACT_APP_API_URL + '/links/'+ id);
}

function getList() {
  return apiService.apiGet(process.env.REACT_APP_API_URL + '/iatg_list');
}

function getListExport(list_id,doc_type,language_code) {

  let data = new FormData();
  data.append('list_id',list_id);
  data.append('doc_type',doc_type);
  data.append('language',language_code);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/iatg_list/export',data);
}

function postList(sortColumn,sortType,page,doc_type,page_size,language_code) {

  let data = new FormData();
  data.append('sort_column',sortColumn);
  data.append('sort_type',sortType);
  data.append('selected_page',page);
  data.append('doc_type',doc_type);
  data.append('page_size',page_size);
  data.append('language',language_code);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/iatg_list',data);
}

function postSelectedList(list_id, sortColumn,sortType,page,doc_type,page_size,language_code) {

  let data = new FormData();
  data.append('sort_column',sortColumn);
  data.append('sort_type',sortType);
  data.append('selected_page',page);
  data.append('doc_type',doc_type);
  data.append('page_size',page_size);
  data.append('language',language_code);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/iatg_list/view/' + list_id,data);
}

function postDetailedList(list_id, sortColumn,sortType,page,doc_type,page_size, language_code) {

  let data = new FormData();
  data.append('list_id',list_id);
  data.append('sort_column',sortColumn);
  data.append('sort_type',sortType);
  data.append('selected_page',page);
  data.append('doc_type',doc_type);
  data.append('page_size',page_size);
  data.append('language',language_code);

  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/iatg_list/detailed',data);
}

function putToList(ids,doc_type) {
  let data = new FormData();
  data.append('guideline_ids',ids);
  data.append('doc_type',doc_type);
  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/iatg_list/items/attach', data);
}

function deleteFromList(list_id, ids,doc_type) {
  let data = new FormData();
  data.append('list_id',list_id);
  data.append('guideline_ids',ids);
  data.append('doc_type',doc_type);
  return apiService.apiPostForm(process.env.REACT_APP_API_URL + '/iatg_list/items/detach', data);
}
