import React, { useEffect , useState} from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import LoadingSpinner from '../spinners/LoadingSpinner'
import ErrorModal from '../modals/ErrorModal';
import Helpers from '../Helpers'
import { useTranslation } from 'react-i18next';

import { guidelinesService } from '../../services/guidelinesService';
import { authenticationService } from '../../services/authenticationService';

function GuidelineDetailsModal(props) {

  const { i18n } = useTranslation();

  const [guideline, setGuideline] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [currentTimestamp, setCurrentTimestamp] = useState(null);

  const [show, setShow] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      handleShow();
      guidelinesService.getGuidelineById(props.guideline_id, i18n.language)
        .then(
            (data) => {
              setGuideline(data.guideline);
            },
            error => {
              if (error.message === '401') {
                authenticationService.logout();
              } else if (error.message === '500') {
                setShow(false);
                setShowErrorModal(true);
              }
            }
        );

    };
    if (props.guideline_id && currentTimestamp !== props.guideline_id_timestamp) {
      setCurrentTimestamp(props.guideline_id_timestamp);
      fetchData();
    }

  }, [props.guideline_id,props.guideline_id_timestamp,i18n.language,currentTimestamp]);



  const {createMarkup} = Helpers();

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const handleClose = () =>
  {
    setShow(false);
    setGuideline(null);
  }
  const handleShow = () => setShow(true);
  const { t } = useTranslation();

  const {arrayAsBadges} = Helpers();

  return (
    <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />

      <Modal
        animation={false}
        show={show }
        onHide={handleClose}
        backdrop="static"
        className="modal-A4"
      >
        { guideline ?
        <>
        <Modal.Header
          className="close-align-self-start" closeButton>
          <Modal.Title>
            {guideline?.module && <><h3>{guideline?.module.number} - {guideline?.module.title}</h3>
            {guideline?.module && guideline?.module.link && <h4>{t('Source')}: <a className="fw-normal" target="_blank" rel="noreferrer" href={guideline?.module.link}>{guideline?.module.link}</a><br/></h4>}

            <h4>{t('Edition')}:&nbsp;<span className="fw-normal">{guideline?.module.edition}</span>,&nbsp;{t('Amendment')}:&nbsp;<span className="fw-normal">{guideline?.module.ammendment}</span></h4></>}
            <h4>{t('Section')}:&nbsp;<span className="fw-normal">{guideline?.section}</span></h4>
            <h4>{t('Section title')}:&nbsp;<span className="fw-normal">{guideline?.section_title}</span></h4>
            {guideline?.term && 0<guideline?.term.length && <h4>{t('Terms')}:&nbsp;<span className="fw-normal">{guideline?.term && arrayAsBadges(guideline?.term.map(item => {
                  return item.title;
              }) ,'orange')}</span></h4>
            }
             {guideline?.rrpl && 0<guideline?.rrpl.length && <h4>{t('RRPL')}:&nbsp;<span className="fw-normal">{ arrayAsBadges(guideline?.rrpl.map(item => {
                return item.title;
            }) ,'orange')}</span></h4>
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="pt-2"
        >
          <pre dangerouslySetInnerHTML={createMarkup(guideline?.text)}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('Close')}
          </Button>
        </Modal.Footer>
        </> :
          <LoadingSpinner/>
        }
      </Modal>
    </>
  );
}

export default GuidelineDetailsModal
