import React from "react";
import Table from 'react-bootstrap/Table'
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';


export default function GuidelinesGridSingle(props) {

  const history = useHistory();
  const { t } = useTranslation();

  const selectGuideline = (guideline_id) => {
   history.push('/links/' + guideline_id);
  }

  return (
      <Table  bordered hover className="custom-responsive double-rowed">
        <thead>
          <tr>
            <th>{t('Date')}</th>
            <th>{t('Document type')}</th>
            <th>{t('Module ID')}</th>
            <th>{t('Number')}</th>
          </tr>
        </thead>
        {
          0 < props.notifications.length ?
          props.notifications?.sort((a, b) => (new Date(a.updated_at).valueOf  > new Date(b.updated_at).valueOf ) ? -1 : 1).map(function(value, index){
            return <tbody
                    className="pointer"
                    key={ index + 'data' }
                    onClick={() => selectGuideline(value.data.guideline?.id)}>
                    <tr key={ index + 'data' }>
                        <td>{value.updated_at}</td>
                        <td>{value.data.guideline?.doc_type_text}</td>
                        <td>{value.data.guideline?.module_number}</td>
                        <td>{value.data.guideline?.section}</td>
                    </tr>
                    <tr>
                       <td colSpan="4">{value.data.message}&nbsp;
                       <span
                        className="pointer link-color hover-underline">{t('Click here to view')}.</span>
                       </td>
                     </tr>
                    </tbody>;
          })
          : <tbody><tr><td colSpan="4">{t('No notifications')}</td></tr></tbody>
        }
      </Table>
  );
}
