import React from 'react';
import { Translation, withTranslation  } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import LoadingSpinner from '../spinners/LoadingSpinner'
import ModalSpinner from '../spinners/ModalSpinner'
import ButtonSpinner from '../spinners/ButtonSpinner';
import ErrorModal from '../modals/ErrorModal';
import Form from 'react-bootstrap/Form';
import ListSaveToFormModal from '../modals/ListSaveToFormModal';
import ListFormModal from '../modals/ListFormModal';
import Select, { createFilter } from 'react-select'
import debounce from 'lodash.debounce';
import queryString  from 'query-string';
import GuidelineDetailsModal from '../modals/GuidelineDetailsModal';
import GuidelinesPagination from './GuidelinesPagination';
import SortableTableHeader from './SortableTableHeader';

import { guidelinesService } from '../../services/guidelinesService';
import { authenticationService } from '../../services/authenticationService';
import { myListCounterService } from '../../services/myListCounterService';

import { SiteConfig }  from '../../SiteConfig';

import Helpers from '../../components/Helpers'



class GuidelinesGrid extends React.Component {

  constructor({ props,i18n }) {

      super(props);

      this.i18n = i18n;



      this.state = {
        error: null,
        initiallyLoaded: false,
        loadingData: false,
        areFiltersLoaded: false,
        page_size: process.env.REACT_APP_REQUIREMENTS_RESULTS_PER_PAGE,
        iatg_terms: [],

        iatg_rrpls: [],
        iatg_modules: [],
        doc_type: null,
        selected_guideline_id: null,
        selected_guideline_id_timestamp: null,
        selected_terms: [],
        selected_rrpls: [],
        text_search: '',
        selected_modules: [],
        sort_column: 'module_number',
        sort_type: 'asc',
        selected_page: 1,
        guidelines_only: false,
        numResults: 0,
        page: 0,
        resultsPerPage: 0,
        pagesTotal: 0,
        allQMPPrinciples: [],
        allRRPLlements:[],
        allTerms: [],
        allStakeholders: [],
        allModules: [],
        guidelines: [],
        cmLists: [],
        save_row_hover: [],
        row_updating: [],
        save_all_updating: false,
        show_toast : false,
        iatg_modules_input_value: '',
        iatg_terms_input_value: '',
        iatg_rrpls_input_value: '',
        window_location : window.location+window.location.search,
        showErrorModal: false,
        showListSaveToFormModal: false,
        showListFormModal: false,
        selectedListGuidelineIds: []

      };
      this.handleSortColumn = this.handleSortColumn.bind(this);
      this.handleSelectChange = this.handleSelectChange.bind(this);
      this.handleClearAll = this.handleClearAll.bind(this);
      this.handleShare = this.handleShare.bind(this);
      this.toggleShowToast = this.toggleShowToast.bind(this);
      this.handleSaveClick = this.handleSaveClick.bind(this);
      this.handleSaveAllClick = this.handleSaveAllClick.bind(this);
      this.fetchGuidelinesData = this.fetchGuidelinesData.bind(this);
      this.paginationEventHandler = this.paginationEventHandler.bind(this);
      this.pageSizeEventHandler = this.pageSizeEventHandler.bind(this);
      this.lowerPaginationEventHandler = this.lowerPaginationEventHandler.bind(this);
      this.lowerPageSizeEventHandler = this.lowerPageSizeEventHandler.bind(this);

      this.fetchFilterData = this.fetchFilterData.bind(this);
      this.determineDisabledOptions  = this.determineDisabledOptions.bind(this);
      this.onTextSearchChange = this.onTextSearchChange.bind(this);
      this.updateRowStatus = this.updateRowStatus.bind(this);
      this.replaceQueryString = this.replaceQueryString.bind(this);
      this.onInputChange = this.handleInputChanged.bind(this);
      this.onCloseErrorModalCallback = this.onCloseErrorModalCallback.bind(this);
      this.showErrorModal = this.showErrorModal.bind(this);
      this.navigateToLinkPage = this.navigateToLinkPage.bind(this);
      this.saveHoverStatus = this.saveHoverStatus.bind(this);
      this.onCloseShowListSaveToFormModalCallback = this.onCloseShowListSaveToFormModalCallback.bind(this);
      this.onCloseListFormModalCallback = this.onCloseListFormModalCallback.bind(this);
      this.showListSaveToForm = this.showListSaveToForm.bind(this);
      this.popOverContainerRef = React.createRef();
      this.upperPaginationRef = React.createRef();
      this.locationarea = React.createRef();



    }

    showListSaveToForm = (e, guideline_id) => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        showListSaveToFormModal: true,
        selectedListGuidelineIds: [guideline_id]
      });
    }


    onCloseShowListSaveToFormModalCallback = (reloadData,showAddListModal) => {
      this.setState({
        showListSaveToFormModal: false,
        showListFormModal:showAddListModal
      });
    };

    onCloseListFormModalCallback = (reloadData) => {
      if (reloadData) {
        this.setState({
          selectedGuidelineId: this.state.selectedGuidelineId
        });
      }

      this.setState({
        showListSaveToFormModal: true,
        showListFormModal:false
      });


    }



    fetchGuidelinesData(scrollToTop) {

      this.setState({
        loadingData: true
      });

      let data = new FormData();
      data.append('doc_type',SiteConfig.docType);
      data.append('text_search',this.state.text_search);
      data.append('selected_modules',this.state.selected_modules);
      data.append('selected_terms',this.state.selected_terms);
      data.append('selected_rrpls',this.state.selected_rrpls);
      data.append('selected_page',this.state.selected_page);
      data.append('page_size',this.state.page_size);
      data.append('sort_column',this.state.sort_column);
      data.append('sort_type',this.state.sort_type);
      data.append('language',this.i18n.language);

      guidelinesService.guidelines(data)
        .then(
          (result) => {


            this.setState({
              initiallyLoaded: true,
              loadingData: false,
              numResults: result.numResults,
              page: result.page,
              resultsPerPage: result.resultsPerPage,
              pagesTotal: result.pagesTotal,
              allQMPPrinciples: result.allQMPPrinciples,
              allRRPLlements: result.allRRPLlements,
              allStakeholders: result.allStakeholders,
              allTerms: result.allTerms,
              allModules: result.allModules,
              cmLists: result.Lists,
              guidelines: result.Guidelines.data

            }, function() {
              if (!this.state.areFiltersLoaded) {
                //get the filter data
                this.fetchFilterData();
              }
              //persist the state in the query string
              this.replaceQueryString();



            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if (error.message === '401') {
              authenticationService.logout();
            } else if (error.message === '500') {
              this.showErrorModal();
            } else {
              this.setState({
                loadingData: false,
                error
              });
            }
          }
        )
    }

    navigateToLinkPage(e, id) {
      e.preventDefault();
      e.stopPropagation();
      this.props.history.push('/links/' + id);
    }

    onCloseErrorModalCallback() {
      this.setState({
        showErrorModal : false
      });
    }

    showErrorModal() {
      this.setState({
        showErrorModal : true
      });
    }

    handleInputChanged(input, reason ,name) {

     if (reason.action === "set-value") {
       return;
     }

     switch (name) {
       case 'iatg_terms':
         this.setState({
           iatg_terms_input_value: input
         });
         break;
       case 'iatg_rrpls':
         this.setState({
           iatg_rrpls_input_value: input
         });
         break;
       case 'iatg_modules':
         this.setState({
           iatg_modules_input_value: input
         });
         break;
       default:
     };
    }

    handleShare =  (e) => {

      e.preventDefault();
      e.stopPropagation();

      navigator.clipboard.writeText(this.state.window_location);

      this.toggleShowToast(e);
    }

    toggleShowToast =  (e) => {
      e.preventDefault();
      e.stopPropagation();

      this.setState({
        show_toast: !this.state.show_toast
      });
    }

    handleClearAll =  (e) => {
      e.preventDefault();
      e.stopPropagation();

      document.getElementById("text_search").value = '';

      this.setState({
        selected_terms: [],
        selected_rrpls: [],
        text_search: '',
        selected_modules: [],
        sort_column: 'module_number',
        sort_type: 'asc',
        selected_page: 1,
        guidelines_only: false,
        iatg_terms_select_values: [],
        iatg_rrpls_select_values: [],
        iatg_modules_select_values: [],
      }, function() {
        this.fetchGuidelinesData();
        this.replaceQueryString();
      });
    }

    onTextSearchChange = (event) => {
    /* signal to React not to nullify the event object */
        event.persist();

        if (!this.debouncedFn) {
          this.debouncedFn =  debounce(() => {
             let searchString = event.target.value;
             this.setState({
               guidelines_only: false,
               selected_page: 1,
               text_search: searchString
             }, function() {
               this.fetchGuidelinesData();
             });
          }, 500);
        }    this.debouncedFn();
    }

    fetchFilterData() {

      guidelinesService.getSearchFilters(SiteConfig.docType,SiteConfig.siteTag,this.i18n.language)
          .then(
              (data) => {
                this.setState({
                  areFiltersLoaded: true,
                  iatg_terms: data.terms.items,
                  iatg_terms_select_values:  data.terms.items.filter(item => -1 < this.state.selected_terms.map(Number).indexOf(item.id)),
                  iatg_rrpls: data.rrpls.items,
                  iatg_rrpls_select_values:  data.rrpls.items.filter(item => -1 < this.state.selected_rrpls.map(Number).indexOf(item.id)),
                  iatg_modules: data.modules.items,
                  iatg_modules_select_values:  data.modules.items.filter(item => -1 < this.state.selected_modules.map(Number).indexOf(item.id))

                },function() {

                })
                //this.determineDisabledOptions();
              },
              error => {
                if (error.message === '401') {
                  authenticationService.logout();
                } else if (error.message === '500') {
                  this.showErrorModal();
                }
              }
          );
    }

    componentDidMount() {

      //read in state from query string
      const query = queryString.parse(window.location.search);

      let qsState = {};

      if (query.text) {
       qsState.text_search = query.text;
      }
      if (query.modules) {
       qsState.selected_modules = query.modules.split(',');
      }
      if (query.terms) {
       qsState.selected_terms = query.terms.split(',');
      }
      if (query.rrpls) {
       qsState.selected_rrpls = query.rrpls.split(',');
      }
      if (query.page) {
       qsState.selected_page = query.page;
      }
      if (query.sort_column) {
       qsState.sort_column = query.sort_column;
      }
      if (query.page_size) {
       qsState.page_size = query.page_size;
      }

      if (query.sort_type) {
        qsState.sort_type = query.sort_type;
      }

      this.setState(qsState, function() {
        this.fetchGuidelinesData();
      });

      var fetchGuidelinesData = this.fetchGuidelinesData;
      var fetchFilterData = this.fetchFilterData;

      this.i18n.on('languageChanged', function(lng) {
        fetchFilterData()
        fetchGuidelinesData(true);
      });

    }

    replaceQueryString() {

      let stateString = '';

      if (this.state.text_search !=='')
        stateString = stateString + "text=" + this.state.text_search + '&';

      if (0 < this.state.selected_modules.length)
        stateString = stateString + "modules=" + this.state.selected_modules.join(',') + '&';

      if (0 < this.state.selected_terms.length)
        stateString = stateString + "terms=" + this.state.selected_terms.join(',') + '&';

      if (0 < this.state.selected_rrpls.length)
        stateString = stateString + "rrpls=" + this.state.selected_rrpls.join(',') + '&';


      if (1 !== this.state.selected_page)
        stateString = stateString + "page=" + this.state.selected_page + '&';

      if ('module_number' !== this.state.sort_column)
        stateString = stateString + "sort_column=" + this.state.sort_column + '&';

      if ("asc" !== this.state.sort_type)
        stateString = stateString + "sort_type=" + this.state.sort_type + '&';

      if (process.env.REACT_APP_REQUIREMENTS_RESULTS_PER_PAGE !== this.state.page_size)
        stateString = stateString + "page_size=" + this.state.page_size + '&'

      if (0 < stateString.length) {
        stateString = '?' + stateString.substring(0, stateString.length - 1);
      } else {
        stateString = window.location.href.split('?')[0];
      }
      window.history.replaceState({}, '', stateString);


      this.setState({
        window_location : -1 < window.location.href.indexOf('?') ? window.location.href : window.location.href+window.location.search
      });

    }




    handleSortColumn(sortColumn, sortType) {
     this.setState({
       guidelines_only: true,
       selected_page: 1,
       sort_column: sortColumn,
       sort_type: sortType
     }, function() {
       this.fetchGuidelinesData();
     });
    }

    determineDisabledOptions  = () => {
      //once we have all the data we can disble options
      let returnTerms = this.state.iatg_terms.map(x => (-1 < this.state.allTerms.indexOf(x.id) ? x : { ...x, disabled: 'yes' }));
      let returnRrpls = this.state.iatg_rrpls.map(x => (-1 < this.state.allRRPLlements.indexOf(x.id) ? x : { ...x, disabled: 'yes' }));
      let returnModules = this.state.iatg_modules.map(x => (-1 < this.state.allModules.indexOf(x.id) ? x : { ...x, disabled: 'yes' }));

      this.setState({
        iatg_terms : returnTerms,
        iatg_rrpls : returnRrpls,
        iatg_modules : returnModules
      });
    }

    lowerPaginationEventHandler  = (page) => {
      this.paginationEventHandler(page,true);
      window.scrollTo({top: this.upperPaginationRef.current.getBoundingClientRect().bottom, behavior: "smooth"})
    }

    paginationEventHandler  = (page,scrollToTop) => {
      this.setState({
        guidelines_only: true,
        selected_page: parseInt(page,10)
      }, function() {
        this.fetchGuidelinesData(scrollToTop);
      });
    }

    lowerPageSizeEventHandler  = (page) => {
      this.pageSizeEventHandler(page,true);
      window.scrollTo({top: this.upperPaginationRef.current.getBoundingClientRect().bottom, behavior: "smooth"})

    }

    pageSizeEventHandler  = (page,scrollToTop) => {
      this.setState({
        guidelines_only: true,
        selected_page: 1,
        page_size: page
      }, function() {
        this.fetchGuidelinesData();
      });
    }

    handleSaveAllClick  = (e) => {
      e.preventDefault();
      e.stopPropagation();

      var GuidelineIds = this.state.guidelines.map(item => {
          //row_updating[item.id] = true;
          return item.id;
      });

      this.setState({
        showListSaveToFormModal: true,
        selectedListGuidelineIds: GuidelineIds
      });
    }

    saveHoverStatus = (value, index) => {
        const clone = [...this.state.save_row_hover];
        clone[index] = value;
        this.setState({
          save_row_hover: clone
        });
    }

    handleSaveClick = (e, id, is_saved_to_list) => {

      e.preventDefault();
      e.stopPropagation();
      //update the is_saved_to_list

      let row_updating = [...this.state.row_updating];
      row_updating[id] = true;
      this.setState({
          row_updating: row_updating
      });

      if (!is_saved_to_list) {

        guidelinesService.putToList([id], SiteConfig.docType)
            .then(
                (data) => {
                  this.updateRowStatus(id, is_saved_to_list, row_updating);
                  myListCounterService.setMyListCount(data.count);
                },
                error => {}
            );
      } else {

        guidelinesService.deleteFromList([id], SiteConfig.docType)
            .then(
                (data) => {
                  this.updateRowStatus(id, is_saved_to_list, row_updating);
                  myListCounterService.setMyListCount(data.count);
                },
                error => {}
            );
      }

    }

    updateRowStatus = (id, is_saved_to_list, row_updating) => {
      let guidelines = [...this.state.guidelines];
      var foundIndex = guidelines.findIndex(x => x.id === id);
      guidelines[foundIndex].is_saved_to_list = !is_saved_to_list;

      row_updating[id] = false;

      this.setState({
          row_updating: row_updating,
          guidelines: guidelines
      });
    }

    handleSelectChange = (selectedOption,ev) => {
      var stateItem = {
        guidelines_only: false,
        selected_page: 1,
      };

      var newIds = []

      if (selectedOption) {
        newIds =  selectedOption.map(option => {
            return option.id;
        });
      }


      switch (ev.name) {
        case 'iatg_terms':
          stateItem.selected_terms = newIds;
          stateItem.iatg_terms_select_values = selectedOption;
          break;
        case 'iatg_rrpls':
          stateItem.selected_rrpls = newIds;
          stateItem.iatg_rrpls_select_values = selectedOption;
          break;
        case 'iatg_modules':
          stateItem.selected_modules = newIds;
          stateItem.iatg_modules_select_values = selectedOption;
          break;
        default:
      };

      this.setState(
        stateItem,
        function() {
          this.fetchGuidelinesData();
        }
      );

    }
    render() {
      const { error, numResults, text_search , show_toast, window_location, initiallyLoaded, loadingData, page_size, guidelines, iatg_terms, iatg_terms_select_values , iatg_rrpls, iatg_rrpls_select_values, iatg_modules, iatg_modules_select_values, iatg_modules_input_value, iatg_terms_input_value, iatg_rrpls_input_value } = this.state;

      const {createMarkup} = Helpers();

      const moduleFilterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        matchFrom: 'any',
        stringify: option => `${option.data.number} ${option.data.title}`,
        trim: true
      };

      const renderModuleOption = (option: Readonly<Option>) => (
        <>
          {option.number && <strong>{option.number}</strong> } {option.title}
        </>
      )

      if (error) {
        return <div>Error: {error.message}</div>;
      } else {
        return (
          <Translation>
            {
              (t, { i18n }) =>
                <>
                { initiallyLoaded && loadingData &&
                  <ModalSpinner/>
                }  <input type="hidden" id="window_location" name="window_location" value={window_location}/>
                <ErrorModal showerrormodal={this.state.showErrorModal} onCloseErrorModal={this.onCloseErrorModalCallback} />
                <ListSaveToFormModal show={this.state.showListSaveToFormModal} guideline_ids={this.state.selectedListGuidelineIds} handleModalOpenClose={this.onCloseShowListSaveToFormModalCallback} />
                <ListFormModal show={this.state.showListFormModal}  handleModalClose={this.onCloseListFormModalCallback} />

                { initiallyLoaded &&
                <div className="controls">
                  <div className="container">

                    <div className="col">
                      <div className="filter">
                          <div className="row flex-wrap d-print-none">
                            <div className="col-md-12">
                              <Toast show={show_toast} onClose={this.toggleShowToast}>
                               <Toast.Header
                                className="justify-content-between">
                                  {t('Added to Clipboard!')}
                               </Toast.Header>
                               <Toast.Body>
                                  <Form.Control
                                    type="text"
                                    readOnly
                                    value={window_location}
                                  />
                                  <Form.Text>
                                    {t('Highlight text above and copy if clipboard is empty')}
                                  </Form.Text>
                               </Toast.Body>
                             </Toast>
                             <div
                               className="float-end pointer hasSpinner btn-arrow d-flex btn btn-none btn-sm"
                               onClick={this.handleClearAll}>
                                 <span>{t('Clear All')}</span>
                                 <div className="link-arrow"><i className="fa fa-trash"></i></div>
                             </div>
                              <div
                                className="float-end pointer me-2 hasSpinner btn-arrow  d-flex btn btn-none btn-sm"
                                onClick={this.handleShare}>
                                  <span>{t('Share')}</span>
                                  <div className="link-arrow"><i className="fa fa-share"></i></div>
                              </div>

                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <label
                                className="mt-0"
                                htmlFor="iatg_terms">{t('Search for a term')}</label>
                              <Select
                                hideSelectedOptions={false}
                                className="select-react"
                                name="iatg_terms"
                                isMulti="true"
                                closeMenuOnSelect={true}
                                blurInputOnSelect={false}
                                inputValue={iatg_terms_input_value}
                                onInputChange={(input, reason) => this.onInputChange(input, reason,'iatg_terms')}
                                value={iatg_terms_select_values}
                                getOptionLabel={option => option.title}
                                getOptionValue={option => option.id}
                                placeholder={t('Select')}
                                onChange={this.handleSelectChange}
                                options={iatg_terms}
                                isOptionDisabled={(option) => option.disabled === 'yes'}
                              />
                            </div>
                          </div>
                          <div className="row">

                            <div className="col-md-6">
                              <label
                               htmlFor="iatg_modules">{t('Modules')}</label>
                              <Select
                                hideSelectedOptions={false}
                                name="iatg_modules"
                                isMulti="true"
                                closeMenuOnSelect={true}
                                blurInputOnSelect={false}
                                inputValue={iatg_modules_input_value}
                                onInputChange={(input, reason) => this.onInputChange(input, reason,'iatg_modules')}
                                value={iatg_modules_select_values}
                                getOptionLabel={renderModuleOption}
                                getOptionValue={option => option.id}
                                placeholder={t('Select')}
                                onChange={this.handleSelectChange}
                                options={iatg_modules}
                                isOptionDisabled={(option) => option.disabled === 'yes'}
                                filterOption={createFilter(moduleFilterConfig)}
                              />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="iatg_rrpls">{t('RRPL')}</label>
                              <Select
                                hideSelectedOptions={false}
                                name="iatg_rrpls"
                                isMulti="true"
                                closeMenuOnSelect={true}
                                blurInputOnSelect={false}
                                inputValue={iatg_rrpls_input_value}
                                onInputChange={(input, reason) => this.onInputChange(input, reason,'iatg_rrpls')}
                                value={iatg_rrpls_select_values}
                                getOptionLabel={option => option.title}
                                getOptionValue={option => option.id}
                                placeholder={t('Select')}
                                onChange={this.handleSelectChange}
                                options={iatg_rrpls}
                                isOptionDisabled={(option) => option.disabled === 'yes'}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <label
                                htmlFor="text_search">{t('Text search')}</label>
                              <Form.Control
                                type="text"
                                name="text_search"
                                id="text_search"
                                defaultValue={text_search}
                                onChange={this.onTextSearchChange}
                                placeholder={t('Search')}
                                />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }

                  { initiallyLoaded ?
                    <>

                      <div className="container">
                      <div className="col">
                        <p><strong>{numResults} {t('results')}</strong></p>
                       </div>
                       <div className="row mb-3">
                         <div className="col d-flex  justify-content-between">
                           <div
                             ref={this.upperPaginationRef}>
                             <GuidelinesPagination onPageSizeChange={this.pageSizeEventHandler} onItemClick={this.paginationEventHandler} page={this.state.page} pages_total={this.state.pagesTotal} page_size={page_size}/>
                           </div>
                           <Button
                             className="d-print-none hasSpinner btn-arrow  d-flex"
                             variant="none"
                             size="sm"
                             onClick={(e) => this.handleSaveAllClick(e)}
                             disabled={this.state.save_all_updating}
                           >
                             <span>{t('Save all')}</span>
                             {(this.state.save_all_updating) && <ButtonSpinner/>}
                             <div className="link-arrow"><i className="fa fa-arrow-right"></i></div>
                           </Button>
                         </div>
                       </div>
                       <div className="col">


                          <div>
                            <div className="flex-table header " role="rowgroup">
                              <SortableTableHeader addClass="module text-nowrap" onHeaderClick={this.handleSortColumn} field="module_number" label={t('DocType') + ' #'} sort_column={this.state.sort_column} sort_type={this.state.sort_type}/>
                              <SortableTableHeader addClass="section" onHeaderClick={this.handleSortColumn} field="section" label={t('Section')} sort_column={this.state.sort_column} sort_type={this.state.sort_type}/>
                              <SortableTableHeader addClass="title" onHeaderClick={this.handleSortColumn} field="guideline.section_title" label={t('Section title')} sort_column={this.state.sort_column} sort_type={this.state.sort_type}/>
                              <div className="flex-row actions d-flex justify-content-center" role="columnheader">
                                <strong>
                                  {t('Save')}
                                </strong>
                              </div>
                            </div>
                              {
                              0 < guidelines.length ?
                                guidelines.map((value, index) => {
                                  return (
                                          <div
                                            className="flex-table hover-effect table-row pointer"
                                            role="rowgroup"
                                            key={ index + 'data' }
                                            onClick={() => {
                                              //need to set a timestamp just in case the same row is clicked twice
                                              this.setState({
                                                selected_guideline_id : value.id,
                                                selected_guideline_id_timestamp : new Date().valueOf()
                                              });
                                            }}>
                                              <div
                                                className="flex-row module"
                                                role="cell"
                                              >
                                                <div className="hidden-900">
                                                  <OverlayTrigger
                                                    key={value.id}
                                                    placement="top"
                                                    overlay={
                                                      <Popover>
                                                        <Popover.Content>
                                                          <pre>{value.module.title}</pre>
                                                        </Popover.Content>
                                                      </Popover>
                                                    }
                                                    >
                                                    <span>{value.module_number}</span>
                                                  </OverlayTrigger>
                                                </div>
                                                <div className="visible-900">
                                                  <div>{t('Module')}</div>
                                                  <span><strong>{value.module_number}</strong>   {value.module.title}</span>
                                                </div>
                                              </div>
                                              <div
                                                className="flex-row section"
                                                role="cell"
                                              >{value.section}</div>
                                              <div
                                                className="flex-row title"
                                                role="cell"
                                              >
                                                <div className="hidden-tablet-landscape">
                                                  <OverlayTrigger
                                                    key={`text${value.id}`}
                                                    placement="top"
                                                    overlay={
                                                      <Popover className="wide" >
                                                        <Popover.Content>
                                                          <pre dangerouslySetInnerHTML={createMarkup(value.decoded_summary_text)}/>
                                                        </Popover.Content>
                                                      </Popover>
                                                    }
                                                    >
                                                    <span dangerouslySetInnerHTML={createMarkup(value.section_title)}/>
                                                  </OverlayTrigger>
                                                </div>
                                                <div className="visible-tablet-landscape" dangerouslySetInnerHTML={createMarkup(value.decoded_summary_text)}/>
                                              </div>
                                              <div
                                                className="flex-row actions d-flex justify-content-end"
                                                role="cell"
                                              >
                                                <Button
                                                  className="hasSpinner btn-arrow d-flex"
                                                  variant="none"
                                                  size="sm"
                                                  onClick={(e) => this.showListSaveToForm(e,value.id)}
                                                  disabled={this.state.row_updating[value.id] || false}>
                                                    <span>{t('Save')}</span>
                                                  {(this.state.row_updating[value.id] || false) && <ButtonSpinner/>}
                                                  <div className="link-arrow"><i className="fa fa-arrow-right"></i></div>
                                                </Button>



                                            </div>
                                          </div>)
                                        ;
                                })
                                : <div className="flex-table table-row w-100">
                                    <div className="flex-row flex-grow-1">{t('No guidelines')}</div>
                                  </div>
                              }
                        </div>
                      </div>
                    </div>
                    <div className="container d-print-none">
                      <div className="col pt-2 mt-2 mb-2 d-flex  justify-content-between">

                        <GuidelinesPagination
                          onPageSizeChange={this.lowerPageSizeEventHandler}
                          onItemClick={this.lowerPaginationEventHandler}
                          page={this.state.page}
                          pages_total={this.state.pagesTotal}
                          page_size={page_size}/>
                          <Button
                            className="hasSpinner btn-arrow d-flex"
                            variant="none"
                            size="sm"
                            onClick={(e) => this.handleSaveAllClick(e)}
                            disabled={this.state.save_all_updating}
                          >
                            <span>{t('Save all')}</span>
                            {(this.state.save_all_updating) && <ButtonSpinner/>}
                              <div className="link-arrow"><i className="fa fa-arrow-right"></i></div>
                          </Button>
                      </div>
                    </div>
                    <GuidelineDetailsModal guideline_id={this.state.selected_guideline_id} guideline_id_timestamp={this.state.selected_guideline_id_timestamp}/>
                  </>
                :
                 <LoadingSpinner heightOffset={0} />
              }
              </>
            }
          </Translation>
        );
      }
    }
  }

export default withTranslation()(GuidelinesGrid);
