
import React, { useEffect , useState} from "react";
import { Link, useHistory , useLocation } from "react-router-dom";

import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';

import ButtonSpinner from '../spinners/ButtonSpinner';

import { useTranslation } from 'react-i18next';

import { Formik, Field, Form, ErrorMessage } from 'formik';

import * as Yup from "yup";

import { authenticationService } from '../../services/authenticationService';
import { myListCounterService } from '../../services/myListCounterService';
import { notificationService } from '../../services/notificationService';
import { notificationsPolling } from '../../services/notificationsPolling';

function LoginForm(props) {

  const notificationsPollingMode = notificationsPolling();

  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    // redirect to home if already logged in
     if (authenticationService.currentUserValue) {
         history.push('/');
     }
   },[history]);



  return (
    <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                    .email(t('Email is invalid'))
                    .required(t('Please enter your email address')),
                password: Yup.string()
                    .min(6, t('Password must be at least 6 characters'))
                    .required(t('Password is required'))
            })}
            onSubmit={fields => {

              setFeedbackText('');

              setSubmitting(true);
              // Fetch the accessToken from the server
              authenticationService.login(fields.email, fields.password)
                  .then(
                      user => {
                          setSubmitting(false);
                          myListCounterService.getMyListCount();
                          notificationService.getNotifications()
                          //start the notifications polling
                          notificationsPollingMode.running = true;
                          const { from } = location.state || { from: { pathname: "/" } };
                          history.push(from);

                      },
                      error => {

                          setSubmitting(false);
                          setFeedbackText(t('Login error'));
                          //setValidated(false);
                      }
                  );
            }}
            >
            {({ errors, status, touched }) => (

                <>
                {feedbackText && <Alert variant="danger">
                  {feedbackText}
                </Alert>}

                <Form>

                  <div className="mb-3">
                      <label htmlFor="email">{t('Email address')}</label>
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-envelope" aria-hidden="true"></i></InputGroup.Text>
                        <Field
                          name="email"
                          type="text"
                          className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}
                           placeholder={t('Enter email')}
                         />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </InputGroup>
                  </div>
                  <div className="mb-3">
                      <label htmlFor="password">{t('Password')}</label>
                      <InputGroup>
                        <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-lock" aria-hidden="true"></i></InputGroup.Text>
                        <Field
                          name="password"
                          type="password"
                          className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}
                          placeholder={t('Password')}
                         />
                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                      </InputGroup>
                  </div>
                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary me-2" disabled={submitting}>{t('Login')}  {submitting && <ButtonSpinner/>}</button>
                  </div>
                  <p>
                    <Link
                      className="pe-3"
                      to="/create-account"
                    >{t('Create account')}</Link>
                    <Link
                      to="/forgotten-password"
                    >{t('Forgot your password?')}</Link>

                  </p>
                  <div className="row">
                  <div className="form-group text-center col-md-12">
                    <small>{t('This site is protected by reCAPTCHA and the Google')}&nbsp;
                        <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">{t('Privacy Policy')}</a> {t('and')}&nbsp;
                        <a target="_blank" rel="noreferrer" href="https://policies.google.com/terms">{t('Terms of Service')}</a> {t('apply')}.
                    </small>
                  </div>
                  </div>
              </Form>

                </>
            )}
       </Formik>



  );
};

export default LoginForm;
