
import React, { useEffect , useState} from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import LoadingSpinner from '../spinners/LoadingSpinner'
import ErrorModal from '../modals/ErrorModal';
import Helpers from '../Helpers'
import { useTranslation } from 'react-i18next';

import { guidelinesService } from '../../services/guidelinesService';
import { authenticationService } from '../../services/authenticationService';

import { SiteConfig }  from '../../SiteConfig';


function GuidelineDetailsCarousel(props) {
  //const [items, setItems] = useState([]);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
   setIndex(selectedIndex);
  };

  const { createMarkup } = Helpers();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [show, setShow] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [guidelines, setGuidelines] = useState([]);

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const handleClose = () =>
  {
    setShow(false);
  }

  const handleShow = () => setShow(true);
  const {arrayAsBadges} = Helpers();
  const { t, i18n } = useTranslation();

  const useModifiedData = (reset_timestamp,sortColumn, sortType, page, pageSize, language) => {

    const [modifiedData, setModifiedData] = useState([])

    useEffect(() => {

      const retrieveData  = async () => {

          setLoadingData(true);
            guidelinesService.postDetailedList(props.list_id, sortColumn, sortType, page, SiteConfig.docType, pageSize, language)
            .then(
                (result) => {
                    setLoadingData(false);
                    setGuidelines(result.guidelines.data);

                    let items = [];

                    result.guidelines.data.forEach((guideline, i) => {

                      items.push(
                        <Carousel.Item
                          key={guideline.id}
                          >
                          {guideline?.module && <><h3>{guideline?.module.number} - {guideline?.module.title}</h3>
                          {guideline?.module && guideline?.module.link && <h4>{t('Source')}: <a className="fw-normal" target="_blank" rel="noreferrer" href={guideline?.module.link}>{guideline?.module.link}</a><br/></h4>}
                            <h4>{t('Edition')}:&nbsp;<span className="fw-normal">{guideline?.module.edition}</span>,&nbsp;{t('Amendment')}:&nbsp;<span className="fw-normal">{guideline?.module.ammendment}</span></h4></>}
                            <h4>{t('Section')}:&nbsp;<span className="fw-normal">{guideline?.section}</span></h4>
                            <h4>{t('Section title')}:&nbsp;<span className="fw-normal">{guideline?.section_title}</span></h4>
                            {guideline?.term && 0<guideline?.term.length && <h4>{t('Terms')}:&nbsp;<span className="fw-normal">{guideline?.term && arrayAsBadges(guideline?.term.map(item => {
                                  return item.title;
                              }) ,'orange')}</span></h4>
                            }
                            {guideline?.rrpl && 0<guideline?.rrpl.length && <h4>{t('RRPL')}:&nbsp;<span className="fw-normal">{ arrayAsBadges(guideline?.rrpl.map(item => {
                                  return item.title;
                              }) ,'orange')}</span></h4>
                            }
                            <div className="carousel-item-inner">
                              <pre
                                dangerouslySetInnerHTML={createMarkup(guideline?.text)}/>
                            </div>


                        </Carousel.Item>,
                      );
                    });

                    setModifiedData(items);

                },
                error => {
                  if (error.message === '401') {
                    authenticationService.logout();
                  }
                }
            );
          }

          retrieveData();

        }, [reset_timestamp,sortColumn, sortType, page, pageSize, language])

      return modifiedData;
    }

  const items = useModifiedData(props.reset_timestamp, props.sort_column, props.sort_type, props.page, props.page_size, i18n.language )


  useEffect(() => {

    if (props.guideline_id) {
      //set the carousel index
      guidelines.forEach((guideline, i) => {
        if (props.guideline_id === guideline.id)
          setIndex(i);
      });
    }

  }, [props.guideline_id, guidelines]);

  useEffect(() => {

    if (props.guideline_id_timestamp) {
      handleShow();
    }

  }, [props.guideline_id_timestamp]);

  return (
    <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      <Modal
        animation={false}
        className="padding-0 modal-A4 carousel-modal"
        show={show }
        onHide={handleClose}
        backdrop="static"

      >
        { !loadingData ?
        <>
        <Modal.Header
          className="no-borderd-flex flex-column"
          closeButton
        >
        </Modal.Header>
        <Modal.Body>

          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            interval={5000000000000}
          >
            {items}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('Close')}
          </Button>
        </Modal.Footer>
        </> :
          <LoadingSpinner/>
        }
      </Modal>
    </>
  );
}

export default GuidelineDetailsCarousel
