import UNSaferGuardLogo from '../assets/img/UNSaferGuardLogo.png';
import FCDO from '../assets/img/FCDO-For-Online.png';
import GICHD from '../assets/img/gichd-logo.svg'

function Footer() {

  return (

    <footer className="footer ">
      <div className="pt-2 pb-2 d-md-flex d-none justify-content-between align-items-center">
        <div>
          <img className="ms-1 me-1 d-none" src={GICHD} alt="UN SaferGuard" width="201" height="35" />

          <img className="ms-1 me-1" src={UNSaferGuardLogo} alt="UN SaferGuard" width="201" height="35" />

          <img className="ms-1 me-1" src={FCDO} alt="FCDO" width="102" height="35" />
        </div>

        <div className='d-flex '>
          <p className="me-3 mb-0">
            <a href="/terms">Terms of Use</a>
          </p>
          <p className="me-3 mb-0">
            <a href="/privacy-policy">Privacy Policy</a>
          </p>
          <p className='mb-0'>
            <a href="https://www.GICHD.org/en/general-contents/cookies-policy/" target='_blank' rel="noreferrer">Cookie Policy</a>
          </p>
        </div>
      </div>
      <div className="py-4 d-md-none d-flex justify-content-center">
        <div className="text-center">
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <img className=" d-none" src={GICHD} alt="UN SaferGuard" width="201" height="35" />

            <img className="mb-3" src={UNSaferGuardLogo} alt="UN SaferGuard" width="201" height="35" />

            <img className="mb-3" src={FCDO} alt="FCDO" width="102" height="35" />
          </div>
          <div >
            <p><a href="/terms">Terms of Use</a></p>
            <p> <a href="/privacy-policy">Privacy Policy</a></p>
            <p>
              <a href="https://www.GICHD.org/en/general-contents/cookies-policy/" target='_blank'  rel="noreferrer">Cookie Policy</a>
            </p>
          </div>
        </div>
      </div>
    </footer>

  );
};

export default Footer;
