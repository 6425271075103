import React from "react";
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import GuidelinesGrids from '../components/grids/GuidelinesGrids';

function Notifications() {

    const { t } = useTranslation();
    const { id } = useParams()

    return <div>
            <Helmet>
                <title>{t('Notifications')}</title>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Notifications')}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col">
              <GuidelinesGrids guideline_id={id}/>
            </div>
            </div>
          </div>
}

export default Notifications;
