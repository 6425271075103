
import Badge from 'react-bootstrap/Badge';

const Helpers = () => {

  const docTypeIdToDocType  = (doctypeId) => {

    var retStr = '';

    switch (doctypeId) {
      case 1:
        retStr = "IMAS"
        break;
      case 2:
        retStr = "IATG"
        break;
      case 3:
        retStr = "AMAS"
        break;
      default:
        retStr = '';
    }
    return retStr;

  }

  const badgeVariant = (type) => {

    var variant = '';

    switch (type.trim()) {
      case 'Shall':
        variant = 'danger';
        break;
      case 'Should':
        variant = 'success';
        break;
      case 'May':
          variant = 'info';
          break;
      default:
          variant = 'info';
    }

    return variant;

  }
  const arrayAsBadges = (arr,type) => {
    return arr.map((item) => <Badge
                              className={'bg-' + (type === 'importance' ? badgeVariant(item) : type)}
                              bg={type === 'importance' ? badgeVariant(item) : type}
                              key={item}>{item}</Badge>)
  }

  const  createMarkup = (str) => {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    //need to remove all dir attributes as conflict with site css
    for (const elm of doc.querySelectorAll('*')) {

      var s = elm.style;
      
      for (var i = s.length - 1; i >= 0; i--)
        if (s[i] === 'text-align')
            s.removeProperty(s[i]);
            
        elm.removeAttribute('dir');      
      }
    return {__html:doc.body.innerHTML};
  }

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const setCookieEU = (c_name,value,exdays) =>
  {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
    document.cookie = c_name + "=" + c_value+"; path=/";
  }

  const getCookieEU = (c_name) =>
  {
    var i,x,y,ARRcookies=document.cookie.split(";");
    for (i=0;i<ARRcookies.length;i++)
    {
      x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
      y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
      x=x.replace(/^\s+|\s+$/g,"");
      if (x===c_name)
      {
          return unescape(y);
      }
    }
  }

   return { badgeVariant, arrayAsBadges, createMarkup, getWindowDimensions, setCookieEU, getCookieEU, docTypeIdToDocType };
}

export default Helpers
