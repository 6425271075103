import React, { useEffect , useState, useRef} from "react";
import GuidelinesPagination from './GuidelinesPagination';

import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import LoadingSpinner from '../spinners/LoadingSpinner'
import ModalSpinner from '../spinners/ModalSpinner'
import ButtonSpinner from '../spinners/ButtonSpinner';
import ErrorModal from '../modals/ErrorModal';
import MyListCSVDownload from '../MyListCSVDownload'
import Helpers from '../Helpers'
import { useTranslation } from 'react-i18next';

import GuidelineDetailsCarousel from '../modals/GuidelineDetailsCarousel';
import SortableTableHeader from './SortableTableHeader';
import { guidelinesService } from '../../services/guidelinesService';
import { authenticationService } from '../../services/authenticationService';
import { myListCounterService } from '../../services/myListCounterService';

import { SiteConfig }  from '../../SiteConfig';

export default function GuidelinesListGrid(props) {

  const upperPaginationRef = useRef(null);

  const [guidelines, setGuidelines] = useState([]);
  const [sortColumn, setSortColumn] = useState('module_number');
  const [sortType, setSortType] = useState('asc');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(process.env.REACT_APP_REQUIREMENTS_RESULTS_PER_PAGE);
  const [pagesTotal, setPagesTotal] = useState(10);
  const [recordsTotal, setRecordsTotal] = useState(null);

  const [rowUpdating, setRowUpdating] = useState([]);
  const [remove_all_updating, setRemove_all_updating] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [canExportList, setCanExportList] = useState(false);

  const [selected_guideline_id, setSelected_guideline_id] = useState(null);
  const [selected_guideline_id_timestamp, setSelected_guideline_id_timestamp] = useState(null);

  const { t } = useTranslation();
  const {createMarkup} = Helpers();

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const handleSortColumn = (sortColumn, sortType) => {
    setSortColumn(sortColumn);
    setSortType(sortType);
    setPage(1);
    return true;
  }

  const lowerPageSizeEventHandler  = (page_size) => {
    window.scrollTo({top: upperPaginationRef.current.getBoundingClientRect().bottom, behavior: "smooth"});
    pageSizeEventHandler(page_size, true);
  }

  const pageSizeEventHandler  = (page_size, scrollToTop) => {
    setPageSize(page_size);
    setPage(1);
  }

  const lowerPaginationEventHandler  = (page_size) => {
    window.scrollTo({top: upperPaginationRef.current.getBoundingClientRect().bottom, behavior: "smooth"});
    paginationEventHandler(page_size, true);
  }

  const paginationEventHandler  = (page, scrollToTop) => {
    setPage(parseInt(page, 10));
  }

  const handleRemoveClick = (e, id) => {

    e.preventDefault();
    e.stopPropagation();

    let newRowUpdating = [...rowUpdating];
    newRowUpdating[id] = true;
    setRowUpdating(newRowUpdating);
    setLoadingData(true);

    guidelinesService.deleteFromList(id, SiteConfig.docType)
      .then(
          (data) => {

            //newRowUpdating[id] = true;
            setRowUpdating(newRowUpdating);
            let updatedGuidelines = guidelines.filter(x=> x.id !== id);
            myListCounterService.setMyListCount(recordsTotal - 1);

            setRecordsTotal(recordsTotal - 1);

            if (0 === updatedGuidelines.length) {
              if (pagesTotal === page) {
                setPage(page-1);
              } else {
                //trigger a fetch
                setPage(0);
                setPage(1);
              }
            } else {
              setGuidelines(updatedGuidelines);
            }
          },
          error => {
            if (error.message === '401') {
              authenticationService.logout();
            } else if (error.message === '500') {
              setShowErrorModal(true);
            }
          }
      );
  }

  const handleRemoveAllClick  = (e) => {
    e.preventDefault();
    e.stopPropagation()

    let row_updating = [...rowUpdating];
    let clnGuidelines = [...guidelines];

    let guidelineIds = clnGuidelines.map(item => {
        //row_updating[item.id] = true;
        return item.id;
    });

    clnGuidelines.forEach((item, i) => {
      row_updating[item.id] = true;
    });

    setRemove_all_updating(true);
    setRowUpdating(row_updating);
    setLoadingData(true);

    guidelinesService.deleteFromList(guidelineIds)
      .then(
          (data) => {
            clnGuidelines.forEach((item, i) => {
              row_updating[item.id] = false;
            });

            setRemove_all_updating(false);
            setRowUpdating(row_updating);

            if (pagesTotal === page) {
              setPage(page-1);
            } else {
              //trigger a fetch
              setPage(0);
              setPage(page);
            }
          },
          error => {
            if (error.message === '401') {
              authenticationService.logout();
            } else if (error.message === '500') {
              setShowErrorModal(true);
            }
          }
      );
  }

  useEffect(() => {

    const retrieveData  = async () => {

        setLoadingData(true);
          guidelinesService.postList(sortColumn, sortType, page, SiteConfig.docType, pageSize)
          .then(
              (result) => {
                  setInitiallyLoaded(true);
                  setGuidelines(result.guidelines.data);
                  setPagesTotal(result.guidelines.last_page);
                  setRecordsTotal(result.guidelines.total);
                  myListCounterService.setMyListCount(result.guidelines.total);
                  setCanExportList(result.canexportlist);
                  setLoadingData(false);
              },
              error => {
                if (error.message === '401') {
                  authenticationService.logout();
                } else if (error.message === '500') {
                  setShowErrorModal(true);
                }
              }
          );

    }

    retrieveData();

  }, [sortColumn,sortType,page, pageSize]);



  return (

      <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      { initiallyLoaded && loadingData &&
        <ModalSpinner/>
      }
      { initiallyLoaded  ?
        <>
          <div className="container">
            { recordsTotal > 0 ?
            <><div className="col d-flex justify-content-center mb-3 d-print-none">
                <Button
                  className="me-2"
                  variant="outline-primary"
                    onClick={(e) =>
                      {
                        window.print();
                      }
                    }
                  ><i className="fa fa-print"></i> {t('Print')}
                </Button>
                { canExportList && <MyListCSVDownload disabled={false}/>}
              </div>

              <div className="col pt-2 mt-2 d-print-none">
                <Button
                  className="float-end hasSpinner"
                  variant="primary"
                  size="sm"
                  onClick={(e) => handleRemoveAllClick(e)}
                  disabled={remove_all_updating}
                >
                  {t('Remove all')}
                  {(remove_all_updating) && <ButtonSpinner/>}
                </Button>
                <div
                  ref={upperPaginationRef}>
                  <GuidelinesPagination onPageSizeChange={pageSizeEventHandler} onItemClick={paginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize}/>
                </div>
              </div>
              <div className="col">
              <div>
                <div className="flex-table header " role="rowgroup">
                  <SortableTableHeader class="module" onHeaderClick={handleSortColumn} field="module_number" label={t('DocType') + ' #'} sort_column={sortColumn} sort_type={sortType}/>
                  <SortableTableHeader class="section" onHeaderClick={handleSortColumn} field="section" label={t('Section')} sort_column={sortColumn} sort_type={sortType}/>
                  <SortableTableHeader class="title" onHeaderClick={handleSortColumn} field="guideline.section_title" label={t('Section title')} sort_column={sortColumn} sort_type={sortType}/>
                  <div className="flex-row actions" role="columnheader">
                    <strong>
                      {t('Save')}
                    </strong>
                  </div>
                </div>
                {
                0 < guidelines.length ?
                  guidelines.map((value, index) => {
                    return (
                            <div
                              className="flex-table table-row pointer"
                              role="rowgroup"
                              key={ index + 'data' }
                              onClick={() => {
                                //need to set a timestamp just in case the same row is clicked twice
                                setSelected_guideline_id(value.id);
                                setSelected_guideline_id_timestamp(new Date().valueOf());
                              }}>
                                <div
                                  className="flex-row module"
                                  role="cell"
                                >
                                  <div className="hidden-900">
                                    <OverlayTrigger
                                      key={value.id}
                                      placement="top"
                                      overlay={
                                        <Popover>
                                          <Popover.Content>
                                            <pre>{value.module.title}</pre>
                                          </Popover.Content>
                                        </Popover>
                                      }
                                      >
                                      <span>{value.module_number}</span>
                                    </OverlayTrigger>
                                  </div>
                                  <div className="visible-900">
                                    <div>{t('Module')}</div>
                                    <span><strong>{value.module_number}</strong>   {value.module.title}</span>
                                  </div>
                                </div>
                                <div
                                  className="flex-row section"
                                  role="cell"
                                >{value.section}</div>
                                <div
                                  className="flex-row title"
                                  role="cell"
                                >
                                  <div className="hidden-tablet-landscape">
                                    <OverlayTrigger
                                      key={`text${value.id}`}
                                      placement="top"
                                      overlay={
                                        <Popover className="wide" >
                                          <Popover.Content>
                                            <pre dangerouslySetInnerHTML={createMarkup(value.decoded_summary_text)}/>
                                          </Popover.Content>
                                        </Popover>
                                      }
                                      >
                                      <span dangerouslySetInnerHTML={createMarkup(value.section_title)}/>
                                    </OverlayTrigger>
                                  </div>
                                  <div className="visible-tablet-landscape" dangerouslySetInnerHTML={createMarkup(value.decoded_summary_text)}/>
                                </div>
                                <div
                                  className="flex-row actions"
                                  role="cell"
                                >
                                  <Button
                                    className="w-100 hasSpinner"
                                    variant="primary"
                                    size="sm"
                                    onClick={(e) => handleRemoveClick(e,value.id)}
                                    disabled={rowUpdating[value.id] || false}>
                                      {t('Save')}
                                    {(rowUpdating[value.id] || false) && <ButtonSpinner/>}
                                  </Button>



                              </div>
                            </div>)
                          ;
                  })
                  : <div className="flex-table table-row w-100">
                      <div className="flex-row flex-grow-1">{t('No guidelines')}</div>
                    </div>
                }
                </div>
              </div>
              <div className="col pt-2 mt-2 d-print-none">
                <Button
                  className="float-end"
                  variant="primary"
                  size="sm"
                  onClick={(e) => handleRemoveAllClick(e)}
                  disabled={remove_all_updating}
                >
                  {t('Remove all')}
                  {(remove_all_updating) && <ButtonSpinner/>}
                </Button>
                <GuidelinesPagination onPageSizeChange={lowerPageSizeEventHandler} onItemClick={lowerPaginationEventHandler} page={page} pages_total={pagesTotal} page_size={pageSize}/>
              </div>
              <GuidelineDetailsCarousel sort_column={sortColumn}  sort_type={sortType} page={page} page_size={pageSize} guideline_id={selected_guideline_id} guideline_id_timestamp={selected_guideline_id_timestamp}/>

            </>
            :
              <div className="col">{t('No List Items')} <Link className="underline" to="/search">{t('Search page')}</Link>.</div>
            }
          </div>
        </>
      :
     <LoadingSpinner heightOffset={200}/>
    }

  </>

  );
}
