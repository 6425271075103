
import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import Helpers from '../components/Helpers';

function Cookies() {
    const { t } = useTranslation();

    const {createMarkup} = Helpers();

    return <div>
            <Helmet>
                <title>{t('Cookie Policy')}</title>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Cookie Policy')}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col">
                <span dangerouslySetInnerHTML={createMarkup(t('Cookie Policy Page'))}/>
              </div>
            </div>
          </div>
}

export default Cookies;
