import React, { useEffect , useState} from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UNSaferGuardLogo from '../assets/img/UNSaferGuardLogo.png';
import AmatLogo from '../assets/img/AMAT-logo-dark-resize.png';
import LanguageSwitcher from './LanguageSwitcher';
import { authenticationService } from '../services/authenticationService';

function HeaderTop()
{
    const [currentUser, setCurrentUser] = useState(null);
   
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const unsubscribe = authenticationService.currentUser.subscribe(data => {
          setCurrentUser(data);
        });
    
        return unsubscribe;
    
    },[]);

    function logout(event) {
        authenticationService.logout();
        event.preventDefault();
    }

    const { t } = useTranslation();
    return (
        <div className="navInnerWrapper bg-grey position-relative">
            <div className="d-flex w-100 justify-content-between align-items-center pt-2 pb-2">
                <div className="d-flex align-items-center">
                    <a href="/" className="me-2 me-lg-4">
                            <img src={AmatLogo} className=" w-100" alt=""/>
                    </a>
                    <span>
                    <img src={UNSaferGuardLogo} className="w-100 " alt=""/>
                    </span>
                </div>
               
                        <div className="d-none d-lg-flex">
                        <div className="nav-link pe-0">
                            <LanguageSwitcher expanded={expanded} setExpanded={setExpanded}/>
                        </div>
                       
                        {!currentUser ?
                            <>
                                <div className="nav-link">
                                    <Link className="" to="/create-account">{t('Create account')}</Link>
                                </div>
                                <div className="nav-link">
                                    <Link className=" " to="/login">{t('Login')}</Link>
                                </div>
                            </>
                            :
                            <>
                                <div className="nav-link">
                                    <span className="d-block user-name text-dark"><i className="fa fa-user-circle" aria-hidden="true"></i>{currentUser?.name} </span>
                                </div>
                                <div className="nav-link">
                                    <Link to="#" onClick={logout}> {t('Log out')} </Link>
                                </div>
                            </>
                        }
                        </div>                        
                    </div>
               
            </div>    
                    
                   
                
        
    );
};

export default HeaderTop;