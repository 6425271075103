import React, { useEffect , useState} from "react";
import { Link, NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { authenticationService } from '../services/authenticationService';
import { myListCounterService } from '../services/myListCounterService';
import LanguageSwitcher from './LanguageSwitcher';

function NavBar() {

  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  const [myListCount, setMyListCount] = useState(0);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const unsubscribe = authenticationService.currentUser.subscribe(data => {
      setCurrentUser(data);
    });

    return unsubscribe;

  },[]);

  useEffect(() => {
    const unsubscribe = myListCounterService.currentMyListCount.subscribe(count => {
      setMyListCount(Number(count));
    });

    return unsubscribe;

  },[]);

  useEffect(() => {

    myListCounterService.getMyListCount();
  },[]);

  function logout() {
    myListCounterService.setMyListCount(0);
    authenticationService.logout();
    setExpanded(false);
  }
  return (
  
    <Navbar  expanded={expanded}  expand="lg" className="navbar navbar-expand-lg navbar-dark  d-print-none" id="mainNav">
       
      <div className="navInnerWrapper">

        {/* <a className="nav-brand mobile" href="/">
          <span className="d-none">{t('Brand Logo')}</span>
        </a> */}

        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : true)}
          aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            >
            <Nav
              className="mb-0"
            >
              {/* <NavLink
                onClick={() => setExpanded(false)}
                className="nav-brand"
                exact={true}
                to="/">{t('')}
              </NavLink> */}

              <NavLink
                onClick={() => setExpanded(false)}
                className="nav-link"
                activeClassName="active"
                exact={true}
                to="/">{t('Home')}
              </NavLink>
              <NavLink
                onClick={() => setExpanded(false)}
                className="nav-link"
                activeClassName="active"
                exact={true}
                to="/search">{t('Search')}
              </NavLink>
              { 0 !== myListCount ?
                <NavLink
                  onClick={() => setExpanded(false)}
                  className="nav-link pe-6 position-relative"
                  activeClassName="active"
                  data-count={myListCount}
                  to="/lists">{t('My List')}
                </NavLink>
                :
                <NavLink
                  onClick={() => setExpanded(false)}
                  className="nav-link"
                  activeClassName="active"
                  to="/lists">{t('My Lists')}
                </NavLink>
              }
              <NavLink
                onClick={() => setExpanded(false)}
                className="nav-link"
                activeClassName="active"
                exact={true}
                to="/glossary-terms">{t('Glossary Terms')}
              </NavLink>
              <NavLink
                onClick={() => setExpanded(false)}
                className="nav-link"
                activeClassName="active"
                exact={true}
                to="/FAQs">{t('FAQs')}
              </NavLink>
            </Nav>
            <span className="d-lg-none">
            <LanguageSwitcher setExpanded={setExpanded}/>
            
           
             {!currentUser ?
                <>
                  <ButtonGroup>
                    <Link onClick={() => setExpanded(false)} className="nav-link btn btn-create btn-large" to="/create-account">{t('Create account')}</Link>
                    <Link onClick={() => setExpanded(false)} className="nav-link btn btn-primary btn-large" to="/login">{t('Login')}</Link>
                  </ButtonGroup>
                </>
              : <>
                  <span className="d-block user-name pt-3 pe-3 pb-3 ps-md-3"><i className="fa fa-user-circle" aria-hidden="true"></i>{currentUser?.name} </span>
                  <Button className="nav-link btn-large" color="inherit" onClick={logout}>{t('Log out')}</Button>
                </>
            } 
            </span>
            </Navbar.Collapse>

      </div>
    </Navbar>

  );
};

export default NavBar;
