import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import Helpers from '../components/Helpers'

import GuidelineLinksGrid from '../components/grids/GuidelineLinksGrid';

function Links() {

    const { t } = useTranslation();
    const { id } = useParams();

    const [guideline, setGuideline] = useState(null);

    const {docTypeIdToDocType} = Helpers();

    const receiveGuideline = (guideline) =>
    {
      setGuideline(guideline);
    }

    return <div>
            <Helmet>
                <title>{t('Links')}</title>
            </Helmet>
            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Guidelines linked to guideline')}: {guideline &&
                    <span> {docTypeIdToDocType(guideline.module.doc_type)} {guideline.module.number}<br/>{t('Section')} {guideline.section}</span>
                  }</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col">
              <GuidelineLinksGrid receiveGuideline={receiveGuideline} guideline_id={id}/>
            </div>
            </div>
          </div>
}

export default Links;
