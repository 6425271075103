import React, { useEffect , useState, useCallback, useRef} from "react";
import { useHistory } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import LoadingSpinner from '../spinners/LoadingSpinner'
import ModalSpinner from '../spinners/ModalSpinner'
import ErrorModal from '../modals/ErrorModal';
import SortableTableHeader from './SortableTableHeader';

import { useTranslation } from 'react-i18next';
import { guidelinesService } from '../../services/guidelinesService';
import { authenticationService } from '../../services/authenticationService';
import { SiteConfig }  from '../../SiteConfig';

export default function TermsGrid(props) {

  const [terms, setTerms] = useState([]);
  const [originalTerms, setOriginalTerms] = useState([]);
  const [listRecordsTotal, setTermRecordsTotal] = useState(null);
  const [loadingTermData, setLoadingTermData] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [termsInitiallyLoaded, setTermsInitiallyLoaded] = useState(false);
  const [sortType, setSortType] = useState('asc');
  const [sortColumn, setSortColumn] = useState('title');

  const { t,i18n } = useTranslation();
  const history = useHistory();
  const filterInput = useRef(null);

  const componentMounted = useRef(true); // (3) component is mounted

  const onCloseErrorModalCallback = () => {
    setShowErrorModal(false);
  }

  const selectTerm = (term_id) => {
   history.push('/search?terms=' + term_id);
  }

  const handleSortColumn = (sortColumn, sortType) => {

    setSortColumn(sortColumn);
    setSortType(sortType);

    let termsArray = [...terms];

    if ('definition' === sortColumn) {
      if (sortType === 'desc') {
        termsArray.sort((a, b) => {
          if (b.definition && a.definition) {
              return b.definition.localeCompare(a.definition);
          } else if (!b.definition && a.definition) {
            return -1;
          } else if (b.definition && !a.definition) {
            return 1;
          } else {
            return 0;
          }
        })
      } else {
        termsArray.sort((a, b) => {
          if (a.definition && b.definition) {
              return a.definition.localeCompare(b.definition);
          } else if (!a.definition && b.definition) {
            return -1;
          } else if (a.definition && !b.definition) {
            return 1;
          } else {
            return 0;
          }
        })
      }
    } else {
      if (sortType === 'desc') {
        termsArray.sort((a, b) => {return b.title.localeCompare(a.title)});
      } else {
        termsArray.sort((a, b) => {return a.title.localeCompare(b.title)});
      }
    }

    setTerms(termsArray);

  }

  i18n.on('languageChanged', function(lng) {
    if (filterInput.current) {
      filterInput.current.value = '';
    }
    setSortType('asc');
    setSortColumn('title');
  })

  const filterList = (e) => {

    const updatedList = originalTerms.filter(item => {
      return item.title.toLowerCase().search(e.target.value.toLowerCase()) !== -1 || (item.definition && item.definition.toLowerCase().search(e.target.value.toLowerCase()) !== -1);
    });
   setTerms(updatedList);
  }




  const retrieveTermData  = useCallback(async () => {


      setLoadingTermData(true);
        guidelinesService.getTerms(SiteConfig.docType,SiteConfig.siteTag,i18n.language)
        .then(
            (result) => {
              setTermsInitiallyLoaded(true);
              setLoadingTermData(false);
              setOriginalTerms(result.items);

              setTerms(result.items.sort((a, b) => {
                return a.id - b.id;
                //return a.title.localeCompare(b.title);
              }));
              //console.log(i18n.language +  ' terms count: ' + result.items.length);
              setTermRecordsTotal(result.items.length);
              componentMounted.current = true;
            },
            error => {

              if (error.message === '401' ) {
                authenticationService.logout();
              } else if (error.message === '500') {
                setShowErrorModal(true);
              } else if (error.message === '403') {
                history.push('/');
              }
              componentMounted.current = true;

            }
        );

  },[history,i18n.language]);

  useEffect(() => {
    if (componentMounted.current){
      componentMounted.current = false;
       retrieveTermData();
    }

  },[retrieveTermData]);



  return (

      <>
      <ErrorModal showerrormodal={showErrorModal} onCloseErrorModal={onCloseErrorModalCallback} />
      { termsInitiallyLoaded && loadingTermData &&
        <ModalSpinner/>
      }
      { termsInitiallyLoaded  ?
        <>

        <div className="pb-4">
            { listRecordsTotal > 0 ?
            <>


              <div className="row">
                <div className="col">
                  <div className="mb-4">
                    <Form.Label htmlFor="q">{t('Filter terms')}</Form.Label>
                    <Form.Control
                      id="q"
                      onChange={filterList}
                      placeholder={t('Enter term')}
                      ref={filterInput}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex-table header " role="rowgroup">
                  <SortableTableHeader addClass="section" onHeaderClick={handleSortColumn} field="title" label={t('Glossary Term')} sort_column={sortColumn} sort_type={sortType}/>
                  <SortableTableHeader addClass="flex-md-grow-1" onHeaderClick={handleSortColumn} field="definition" label={t('Description')} sort_column={sortColumn} sort_type={sortType}/>
                  <div className="flex-row associated" role="columnheader">
                  </div>
                </div>

                { 0 < terms.length ? terms.map(function(value, index){
                  return (
                          <div
                            className="flex-table table-row"
                            role="rowgroup"
                            key={index + 'data'}
                          >
                            <div
                              className="flex-row section  strong-single-col"
                              role="cell"
                            >
                              <strong>{value.title}</strong>
                            </div>
                            <div
                              className="flex-row flex-md-grow-1"
                              role="cell"
                            >{value.definition}</div>
                            <div
                              className="flex-row associated"
                              role="cell">
                              <div className="position-relative pe-4">
                                <Button
                                  variant="none"
                                  size="sm"
                                  onClick={() => selectTerm(value.id)}
                                  className="btn-guidelines hasSpinner btn-arrow d-flex btn btn-none btn-sm"
                                >
                                <span>{t('View associated guidelines')}</span>

                                <div className="link-arrow"><i className="fa fa-search"></i></div>

                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                    })
                    : <div className="flex-table table-row w-100">
                        <div className="flex-row flex-grow-1">{t('No terms')}</div>
                      </div>
                  }
                </div>
            </>
            :
              <>{t('No terms')}.</>
            }
          </div>
        </>
      :
     <LoadingSpinner heightOffset={200}/>
    }

  </>

  );
}
