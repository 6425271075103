
import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import LoginForm from '../components/forms/LoginForm';
import { SiteConfig }  from '../SiteConfig';

function Login() {
    const { t } = useTranslation();
    return <div>
            <Helmet>
                <title>{t('Login')}</title>
                  <bodyAttributes class={SiteConfig.siteTag}></bodyAttributes>
            </Helmet>
            <div className="container">
              <div className="controls">
                <div className="row">
                  <div className="col">
                    <h1>{t('Login')}</h1>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <LoginForm/>
                </div>
              </div>
            </div>
          </div>
}

export default Login;
