
import React from 'react';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import TermsGrid from '../components/grids/TermsGrid';

function GlossaryTerms() {
    const { t } = useTranslation();
    return <div>
            <Helmet>
                <title>{t('Glossary Terms')}</title>
            </Helmet>

            <div className="controls">
              <div className="container">
                <div className="col">
                  <h1>{t('Glossary Terms')}</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col">
                <TermsGrid/>
              </div>
            </div>
          </div>
}

export default GlossaryTerms;
