
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Cookies from './pages/Cookies';
import Contact from './pages/Contact';
import FAQS from './pages/FAQS';
import Home from './pages/Home';
import Links from './pages/Links';
import Lists from './pages/Lists';
import Login from './pages/Login';
import MyList from './pages/MyList';
import MyLists from './pages/MyLists';
import Notifications from './pages/Notifications';
import Register from './pages/Register';
import ForgottenPassword from './pages/ForgottenPassword';
import ResetPassword from './pages/ResetPassword';
import Search from './pages/Search';
import Shared from './pages/Shared';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import GlossaryTerms from './pages/GlossaryTerms';

import NavBar from './components/NavBar';
import Footer from './components/Footer';
import LoadingSpinner from './components/spinners/LoadingSpinner'
import ScrollHandler from "./components/ScrollHandler";
import NavResize from './components/NavResize';
import { PrivateRoute } from './hooks/PrivateRoute';
import { notificationsPolling } from './services/notificationsPolling';

import './App.scss';
import HeaderTop from './components/HeaderTop';

function App() {

  notificationsPolling();

  return (
   <Suspense fallback={<LoadingSpinner heightOffset={0}/>}>
      <div className="App">
        <ScrollHandler/>
        <HeaderTop/>
        <NavBar/>
        <NavResize/>

        <main>
          <Switch>
            <Route path="/" component={Home} exact />
            <PrivateRoute path="/search" component={Search} />
            <PrivateRoute path="/mylist" component={MyList} />
            <PrivateRoute path="/lists/:id" component={MyLists} />
            <PrivateRoute path="/links/:id" component={Links} />
            <PrivateRoute path="/lists" component={Lists} />
            <PrivateRoute path="/notifications/:id" component={Notifications} />
            <PrivateRoute path="/notifications/" component={Notifications} />
            <PrivateRoute path="/shared/:id" component={Shared} />
            <PrivateRoute path="/glossary-terms" component={GlossaryTerms} />
            <Route path="/login" component={Login} />
            <Route path="/create-account" component={Register} />
            <Route path="/forgotten-password" component={ForgottenPassword} />
            <Route path="/password/reset/:token" component={ResetPassword} />
            <Route path="/contact" component={Contact} />
            <Route path="/faqs" component={FAQS} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/cookies" component={Cookies} />
          </Switch>
        </main>
        <Footer/>
       
    </div>
  </Suspense>
  );
}

export default App;
