import Spinner from 'react-bootstrap/Spinner';

function ButtonSpinner() {

  return (
    <Spinner
     as="span"
     animation="border"
     size="sm"
     role="status"
     aria-hidden="true"
   />
  )
};

export default ButtonSpinner;
