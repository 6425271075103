import React, { useEffect , useState} from "react";
import Alert from 'react-bootstrap/Alert';
import ButtonSpinner from '../spinners/ButtonSpinner';
import LoadingSpinner from '../spinners/LoadingSpinner'
import ModalSpinner from '../spinners/ModalSpinner'
import { useTranslation } from 'react-i18next';

import { listsService } from '../../services/listsService';
import { authenticationService } from '../../services/authenticationService';
import { myListCounterService } from '../../services/myListCounterService';

import { SiteConfig }  from '../../SiteConfig';

function ListForm(props) {

  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [listName, setListName] = useState("");

  useEffect(() => {

    setLoadingData(true);
     listsService.getListById(props.list_id)
     .then(
         (result) => {
           setInitiallyLoaded(true);
           setLoadingData(false);
           setListName(t(result.iatg_list.list_name));
         },
         error => {

           if (error.message === '401' ) {
             authenticationService.logout();
           } else if (error.message === '500') {
             //setShowErrorModal(true);
           } else if (error.message === '403') {
            // history.push('/');
           }

         }
     );

   },[props.list_id, t]);

   const handleSubmit = async(e) => {
     e.preventDefault();

     listsService.deleteList(props.list_id, SiteConfig.docType)
       .then(
           data => {
               setSubmitting(false);
               props.onSuccessfulFormSubmit(true);
               myListCounterService.setMyListCount(data.count);
           },
           error => {
               setSubmitting(false);
               setFeedbackText(t('Delete list error'));
               //setValidated(false);
           }
       );
   }



  return (

              <>
              { initiallyLoaded && loadingData &&
                <ModalSpinner />
              }
              { initiallyLoaded  ?

                <>
                  {feedbackText && <Alert variant="danger">
                    {feedbackText}
                  </Alert>}

                  <form  onSubmit={handleSubmit}>
                    <div className="mb-3">
                      {t('Are you sure you want to delete the list') + ' \'' + listName + '\' '}? {t('This will not delete the guidelines')}
                    </div>
                    <div className="mb-3">
                      <button type="submit" className="btn btn-danger w-100" disabled={submitting}>{t('Delete')} {submitting && <ButtonSpinner/>}</button>
                    </div>
                </form>

              </>
              :
             <LoadingSpinner fixedHeight={125}/>

            }
            </>




  );
};

export default ListForm;
