import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

import { SiteConfig }  from './SiteConfig';

i18n
  .use(HttpApi) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //languages: ['ar','de','en','es','fr','ru'],
    languages: ['ar','en','es','fr','pt','ru'],
    fallbackLng: 'en',
    debug: true,
    load: 'languageOnly',
    /*languageOnly : true,*/
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    ns: [SiteConfig.siteTag],
    defaultNS:  SiteConfig.siteTag,
    backend: {
      loadPath: process.env.REACT_APP_i18_URL + '/{{lng}}.json',
      allowMultiLoading: true,
      crossDomain: true,
      parse: function(data) {
        return JSON.parse(data);
      }
    }, detection: {
        // order and from where user language should be detected
        order: ['localStorage', 'querystring', 'cookie', 'header'],
        lookupQuerystring: 'locale',
        lookupCookie: 'locale',
        lookupHeader: 'accept-language',
        lookupFromPathIndex: 0,

      }
  }, (err: Error, t: (...params: any[]) => string) => {
  if (err) return console.error(err)
});

export default i18n;
