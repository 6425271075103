import Spinner from 'react-bootstrap/Spinner';

function ModalSpinner(props) {
  return (
  <div className="modal-spinner d-flex  justify-content-center align-items-center" >
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
  )
};


export default ModalSpinner;
